import React from "react";
import { StyledFlexMenu } from "../styles/FlexMenu";
import { logout } from "../actions/auth";
import { connect } from "react-redux";

function FlexMenu({ setPage, page, icon, active, colorRed, logout, name }) {
  return (
    <>
      <StyledFlexMenu className="flexMenu">
        {icon ? <img src={icon} alt={page} /> : null}
        <button
          className={active ? "active" : "inactive"}
          onClick={() => (page === "Sair da Conta" ? logout() : setPage(page))}
          style={colorRed ? { color: "#A11722" } : null}
        >
          {name}
        </button>
      </StyledFlexMenu>
    </>
  );
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(FlexMenu);
