import React from "react";
import { Link } from "react-router-dom";

function TermsUse() {
  return (
    <>
      <h1>Termos e condições de uso do site - Alô Concursos</h1>
      <p>
        Seja bem-vindo ao nosso site. Leia com atenção todos os termos abaixo.
        <br />
        <br />
        Este documento, e todo o conteúdo do site é oferecido por{" "}
        <span>Aranan Tecnologias LTDA.</span> neste termo representado apenas
        por “EMPRESA”, que regulamenta todos os direitos e obrigações com todos
        que realizam cadastro no site, denominado neste termo como “USUÁRIO”,
        resguardado todos os direitos previstos na legislação, trazem as
        cláusulas abaixo como requisito para acesso e visita do mesmo, situado
        no endereço <span>www.aloconcursos.com.</span> <br />
        <br /> O cadastro no website implica automaticamente na leitura e
        aceitação tácita do presente termo de uso a seguir. Este termo foi
        atualizado pela última vez em 4 de agosto de 2024.
      </p>
      <h5>1. DA FUNÇÃO DO SITE</h5>
      <p>
        Este site foi criado e desenvolvido com a função de trazer conteúdo
        informativo de alta qualidade, serviços personalizados de alertas e
        acompanhamento de editais de concursos públicos. A EMPRESA busca através
        da criação de conteúdo de alta qualidade, desenvolvido por profissionais
        da área, trazer o conhecimento ao alcance de todos, assim como a
        divulgação dos próprios serviços. Nesta plataforma, poderá ser realizado
        tanto a divulgação de material original de alta qualidade, assim como a
        divulgação de produtos e serviços de terceiros.
        <br />
        <br /> Todo o conteúdo presente neste site foi desenvolvido buscando
        fontes e materiais de confiabilidade, assim como são baseados em estudos
        sérios e respeitados, através de pesquisa de alto nível.
        <br />
        <br /> Todo o conteúdo presente neste site foi desenvolvido buscando
        fontes e materiais de confiabilidade, assim como são baseados em estudos
        sérios e respeitados, através de pesquisa de alto nível. Todo o conteúdo
        é atualizado periodicamente, porém, pode conter em algum artigo, vídeo
        ou imagem, alguma informação que não reflita a verdade atual, não
        podendo a EMPRESA ser responsabilizada de nenhuma forma ou meio por
        qualquer conteúdo que não esteja devidamente atualizado.
        <br />
        <br />É de responsabilidade do usuário usar todas as informações
        presentes no site com senso crítico, utilizando apenas como fonte de
        informação, e sempre buscando especialistas da área para a solução
        concreta do seu conflito.
      </p>
      <h5>2. DO ACEITE DOS TERMOS</h5>
      <p>
        Este documento, chamado “Termos de Uso”, aplicáveis a todos os usuários
        do site, foi desenvolvido por{" "}
        <Link href="https://diegocastroadvogado.com.br/modelo-de-termos-de-uso-para-blogs-e-sites/">
          Diego Castro Advogado – OAB/PI 15.613
        </Link>
        , modificado com permissão para este site.
        <br />
        <br /> Este termo específica e exige que todo usuário ao cadastrar-se no
        site da EMPRESA, leia e compreenda todas as cláusulas do mesmo, visto
        que ele estabelece entre a EMPRESA e o USUÁRIO direitos e obrigações
        entre ambas as partes, aceitos expressamente pelo USUÁRIO a permanecer
        navegando no site da EMPRESA.
        <br />
        <br /> Ao prosseguir com o cadastro acessando o site, o USUÁRIO expressa
        que aceita e entende todas as cláusulas, assim como concorda
        integralmente com cada uma delas, sendo este aceite imprescindível para
        a permanência na mesma. Caso o USUÁRIO discorde de alguma cláusula ou
        termo deste contrato, o mesmo deve imediatamente interromper o seu
        registro de todas as formas e meios. <br />
        Este termo pode e irá ser atualizado periodicamente pela EMPRESA, que se
        resguarda no direito de alteração, sem qualquer tipo de aviso prévio e
        comunicação. É importante que o USUÁRIO confira sempre se houve
        movimentação e qual foi a última atualização do mesmo no começo da
        página.
      </p>
      <h5>3. DO GLOSSÁRIO</h5>
      <p>
        Este termo pode conter algumas palavras específicas que podem não ser de
        conhecimento geral. Entre elas:
        <ul>
          <li>
            <span>USUÁRIO: </span>
            Todo e qualquer usuário do site, de qualquer forma e meio, que
            acesse através de computador, notebook, tablet, celular ou quaisquer
            outros meios, o website ou plataforma da empresa.
          </li>
          <li>
            <span>NAVEGAÇÃO: </span>O ato de visitar páginas e conteúdo do
            website ou plataforma da empresa.
          </li>
          <li>
            <span>CADASTRO:</span> O ato de realizar cadastro no site por meio
            de dados pessoais para identificação e geração de dados de acesso,
            como usuário e senha, no site da EMPRESA a fim de acessar conteúdo
            restrito.
          </li>
          <li>
            <span>COOKIES:</span> Pequenos arquivos de textos gerados
            automaticamente pelo site e transmitido para o navegador do
            visitante, que servem para melhorar a usabilidade do visitante.{" "}
          </li>
          <li>
            <span>LOGIN:</span> Dados de acesso do visitante ao realizar o
            cadastro junto a EMPRESA, dividido entre usuário e senha, que dá
            acesso a funções restritas do site.
          </li>
          <li>
            <span>HIPERLINKS:</span> São links clicáveis que podem aparecer pelo
            site ou no conteúdo, que levam para outra página da EMPRESA ou site
            externo.
          </li>
          <li>
            <span>OFFLINE:</span> Quando o site ou plataforma se encontra
            indisponível, não podendo ser acessado externamente por nenhum
            usuário.
          </li>
        </ul>
        Em caso de dúvidas sobre qualquer palavra utilizada neste termo, o
        USUÁRIO deverá entrar em contato com a EMPRESA através dos canais de
        comunicação encontrados no site.
      </p>
      <h5>4. DO ACESSO AO SITE</h5>
      <p>
        O Site e plataforma funcionam normalmente 24 (vinte e quatro) horas por
        dia, porém podem ocorrer pequenas interrupções de forma temporária para
        ajustes, manutenção, mudança de servidores, falhas técnicas ou por ordem
        de força maior, que podem deixar o site indisponível por tempo limitado.{" "}
        <br />
        <br />A EMPRESA não se responsabiliza por nenhuma perda de oportunidade
        ou prejuízos que esta indisponibilidade temporária possa gerar aos
        usuários. <br />
        <br />
        Em caso de manutenção que exigirem um tempo maior, a EMPRESA irá
        informar previamente aos clientes da necessidade e do tempo previsto em
        que o site ou plataforma ficará offline.
        <br />
        <br />O acesso ao site só é permitido a maiores de 18 anos de idade ou
        que possuírem capacidade civil plena. Para acesso de menores de idade, é
        necessária a expressa autorização dos pais ou tutores, ficando os mesmos
        responsáveis sobre qualquer compra ou acesso efetuados pelo mesmo.
        <br />
        <br />
        Caso seja necessário realizar um cadastro junto a plataforma, onde o
        USUÁRIO deverá preencher um formulário com seus dados e informações,
        para ter acesso a alguma parte restrita, ou realizar alguma compra.
        <br />
        <br />
        Todos os dados estão protegidos conforme a Lei Geral de Proteção de
        Dados, e ao realizar o cadastro junto ao site, o VISITANTE concorda
        integralmente com a coleta de dados conforme a Lei e com a Política de
        Privacidade da EMPRESA.
      </p>
      <h5>5. DA LICENÇA DE USO E CÓPIA</h5>
      <p>
        O visitante poderá acessar todo o conteúdo do website, como artigos,
        vídeos, imagens, produtos e serviços, não significando nenhum tipo de
        cessão de direito ou permissão de uso, ou de cópia dos mesmos. Todos os
        direitos são preservados, conforme a legislação brasileira,
        principalmente na Lei de Direitos Autorais (regulamentada na{" "}
        <Link to="#">Lei nº 9.610/18</Link>), assim como no Código Civil
        brasileiro (regulamentada na <Link to="#">Lei nº 10.406/02</Link>), ou
        quaisquer outras legislações aplicáveis. Todo o conteúdo do site é
        protegido por direitos autorais, e seu uso, cópia, transmissão, venda,
        cessão ou revenda, deve seguir a lei brasileira, tendo a EMPRESA todos
        os seus direitos reservados, e não permitindo a cópia ou utilização de
        nenhuma forma e meio, sem autorização expressa e por escrita da mesma. A
        EMPRESA poderá em casos concretos permitir pontualmente exceções a este
        direito, que serão claramente destacados no mesmo, com a forma e
        permissão de uso do conteúdo protegido. Este direito é revogável e
        limitado às especificações de cada caso.
      </p>
      <h5>6. DAS OBRIGAÇÕES</h5>
      <p>
        O USUÁRIO ao utilizar o website da EMPRESA, concorda integralmente em:
        <ul style={{ listStyle: "inside" }}>
          <li style={{ marginLeft: "0.8rem" }}>
            De nenhuma forma ou meio realizar qualquer tipo de ação que tente
            invadir, hacker, destruir ou prejudicar a estrutura do site,
            plataforma da EMPRESA ou de seus parceiros comerciais. Incluindo-se,
            mas não se limitando, ao envio de vírus de computador, de ataques de
            DDOS, de acesso indevido por falhas da mesma ou quaisquer outras
            formas e meios.
          </li>
          <li style={{ marginLeft: "0.8rem" }}>
            De não realizar divulgação indevida nos comentários do site de
            conteúdo de SPAM, empresas concorrentes, vírus, conteúdo que não
            possua direitos autorais ou quaisquer outros que não seja pertinente
            a discussão daquele texto, vídeo ou imagem.
          </li>
          <li style={{ marginLeft: "0.8rem" }}>
            Da proibição em reproduzir qualquer conteúdo do site ou plataforma
            sem autorização expressa, podendo responder civil e criminalmente
            pelo mesmo. Com a Política de Privacidade do site, assim como
            tratamos os dados referentes ao cadastro e visita no site, podendo a
            qualquer momento e forma, requerer a exclusão dos mesmos, através do
            formulário de contato.
          </li>
        </ul>
      </p>
      <h5>7. DA MONETIZAÇÃO E PUBLICIDADE</h5>
      <p>
        A EMPRESA pode alugar ou vender espaços publicitários na plataforma, ou
        no site, diretamente aos anunciantes, ou através de empresas
        especializadas com o Adsense (
        <Link to="https://www.google.com/">Google</Link>), Taboola ou outras
        plataformas especializadas.
        <br />
        <br />
        Essas publicidades não significam nenhuma forma de endosso ou
        responsabilidade pelos mesmos, ficando o USUÁRIO responsável pelas
        compras, visitas, acessos ou quaisquer ações referentes às estas
        empresas. <br />
        <br />
        Todas as propagandas no site ou plataforma serão claramente destacadas
        como publicidade, como forma de disclaimer da EMPRESA e de conhecimento
        do USUÁRIO. <br />
        <br />
        Em casos de compra de produtos ou serviços, será possível a devolução em
        até 07 (sete) dias, conforme o Código de Defesa do Consumidor. <br />
        <br />
        Estes anúncios podem ser selecionados pela empresa de publicidade
        automaticamente, conforme as visitas recentes do USUÁRIO, assim como
        baseado no seu histórico de busca, conforme as políticas de acesso da
        plataforma.
      </p>
      <h5>8. DOS TERMOS GERAIS</h5>
      <p>
        O Site irá apresentar hiperlinks durante toda a sua navegação, que podem
        levar diretamente para outra página da EMPRESA ou para sites externos.
        <br />
        <br />
        Apesar da EMPRESA apenas criar links para sites externos de extrema
        confiança, caso o usuário acesse um site externo, a EMPRESA não tem
        nenhuma responsabilidade pelo meio, sendo uma mera indicação de
        complementação de conteúdo, ficando o mesmo responsável pelo acesso,
        assim como sobre quaisquer ações que venham a realizar neste site.
        <br />
        <br />
        Em caso que ocorra eventuais conflitos judiciais entre o USUÁRIO e a
        EMPRESA, o foro elegido para a devida ação será o da comarca da Empresa,
        mesmo que haja outro mais privilegiado.
        <br />
        <br /> Este Termo de uso é válido a partir de 21 de setembro de 2024.
      </p>
    </>
  );
}

export default TermsUse;
