import React, { useEffect, useRef } from "react";
import { StyledPopUpNew } from "../styles/PopUpNew";
import { Link } from "react-router-dom";

function PopUpNew({ openState, setOpenState, setOverlay, additionalCss }) {
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenState(false);
      setOverlay(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const combinedStyles = {
    display: openState ? "flex" : "none",
    ...(additionalCss || {}),
  };
  return (
    <StyledPopUpNew ref={ref} style={combinedStyles}>
      <p>
        <Link to="/planos">Assine</Link> para desbloquear
      </p>
    </StyledPopUpNew>
  );
}

export default PopUpNew;
