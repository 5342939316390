import React, { useEffect, useState } from "react";
import { StyledUserAccount } from "../styles/UserAccountAssinatura.js";
import BoxUserAccount from "./BoxUserAccount.jsx";
import Loader from "../UI/Loader.jsx";
import { Link } from "react-router-dom";
import visa from "../assets/visa.png";
import mastercard from "../assets/mastercard.png";
import americanExpress from "../assets/americanExpress.png";
import elo from "../assets/elo.png";
import { conversaoDataMes } from "../tools/functions";
import { useSelector } from "react-redux";
import Skeleton from "./Skeleton.jsx";

function UserAccountAssinatura({ dados }) {
  const user = useSelector((state) => state.auth.user);
  const Token = process.env.REACT_APP_TOKEN;
  const token = localStorage.getItem("access");
  const URL = process.env.REACT_APP_API_URL;
  const [firstRender, setFirstRender] = useState(false);
  const [dataAfterFirstRender, setDataAfterFirstRender] = useState(null);
  const [reRender, setReRender] = useState(false);
  const [popUpshow, setPopUpShow] = useState(false);
  const [sucessCancel, setSucessCancel] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [loading, setLoading] = useState(null);
  const [showSkeleton, setShowSkeleton] = useState(false);

  useEffect(() => {
    setFirstRender(true);
    setDataAfterFirstRender(dados);
  }, []);

  useEffect(() => {
    async function requisicaoParaPegarDados(email) {
      setTimeout(() => {
        setShowSkeleton(true); // Exibe skeleton apenas após X milissegundos
      }, 1000);

      const response = await fetch(`${URL}/user/users/?emails=${email}`, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      });
      const data = await response.json();

      return data;
    }

    let ignoreFirstRender = firstRender;
    if (ignoreFirstRender) {
      requisicaoParaPegarDados(user.email).then((data) => {
        setDataAfterFirstRender(data.results[0]);
      });
    }
  }, [user, Token, URL, firstRender, reRender]);

  function popUpCancel() {
    setPopUpShow(true);
    setOverlay(true);
  }
  function popUpCancelFalse() {
    setPopUpShow(false);
    setOverlay(false);
  }
  function popUpCancelNext() {
    setPopUpShow(false);
    setLoading(true);
  }
  function sucessCancelFalse() {
    setSucessCancel(false);
    setOverlay(false);
    setReRender(!reRender);
  }
  async function cancelamento() {
    popUpCancelNext();
    fetch(`${URL}/api-stripe/cancel-subscription`, {
      method: "POST",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json(); // Receber a resposta como JSON
      })
      .then(() => {
        setLoading(false);
        setSucessCancel(true);
      })
      .catch((error) => console.error("Error:", error));
  }

  return (
    <StyledUserAccount>
      {overlay && <div className="overlay" />}
      {dataAfterFirstRender ? (
        <>
          <h1>Assinatura</h1>
          <BoxUserAccount tittle={"Detalhes do plano"}>
            <div>
              <span>
                {dataAfterFirstRender.interval
                  ? dataAfterFirstRender.interval === "month"
                    ? "Premium"
                    : "Premium Plus"
                  : "Plano Basic"}
              </span>
              <p>
                {dataAfterFirstRender.interval
                  ? dataAfterFirstRender.interval === "month"
                    ? "Buscas ilimitadas; busca por cargos e concursos; acesso a todos os filtros; informações completa sobre os concursos."
                    : "Buscas ilimitadas; busca por cargos e concursos; acesso a todos os filtros; informações completa sobre os concursos; 25% de desconto."
                  : "Buscas ilimitadas; busca por cargos e concursos; informações completas sobre os concursos."}
              </p>
            </div>
          </BoxUserAccount>
        </>
      ) : showSkeleton ? (
        <>
          <Skeleton width={"177.7px"} height={"42px"} />
          <div className="info">
            <Skeleton width={"145.516px"} height={"22.406px"} />
          </div>
          <Skeleton width={"800px"} height={"100.812px"} />
        </>
      ) : null}

      {dataAfterFirstRender ? (
        dataAfterFirstRender.interval ? (
          dataAfterFirstRender.cancel_at_period_end ? (
            <BoxUserAccount tittle={"Informações de pagamento"}>
              <div>
                <span>Validade da assinatura</span>
                <p>
                  {conversaoDataMes(dataAfterFirstRender.current_period_end)}
                </p>
                <div className="flexCancelTime">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <mask
                      id="mask0"
                      style={{ maskType: "alpha" }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <rect width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0)">
                      <path
                        d="M12 17C12.2833 17 12.5208 16.9042 12.7125 16.7125C12.9042 16.5208 13 16.2833 13 16C13 15.7167 12.9042 15.4792 12.7125 15.2875C12.5208 15.0958 12.2833 15 12 15C11.7167 15 11.4792 15.0958 11.2875 15.2875C11.0958 15.4792 11 15.7167 11 16C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17ZM11 13H13V7H11V13ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                        fill="#BD791B"
                      />
                    </g>
                  </svg>
                  <p>
                    <strong>Você cancelou sua assinatura.</strong> Ela não será
                    renovada de forma automática após o fim da validade.{" "}
                  </p>
                </div>
              </div>
            </BoxUserAccount>
          ) : (
            <BoxUserAccount tittle={"Informações de pagamento"}>
              <div>
                <span>Próximo pagamento</span>
                <p>
                  {conversaoDataMes(dataAfterFirstRender.current_period_end)}
                </p>
                <div className="flexCard">
                  <img
                    className="bandeira"
                    src={
                      dataAfterFirstRender.brand_card === "visa"
                        ? visa
                        : dataAfterFirstRender.brand_card === "mastercard"
                        ? mastercard
                        : dataAfterFirstRender.brand_card === "elo"
                        ? elo
                        : americanExpress
                    }
                    alt="paymentPlaceholder"
                  />
                  <p>•••• •••• •••• {dataAfterFirstRender.card_last4}</p>
                </div>
              </div>
            </BoxUserAccount>
          )
        ) : (
          <BoxUserAccount tittle={"Informações de pagamento"}>
            <div>
              <span>Validade da assinatura</span>
              <div className="flexCancelTime">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <mask
                    id="mask0"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0)">
                    <path
                      d="M12 17C12.2833 17 12.5208 16.9042 12.7125 16.7125C12.9042 16.5208 13 16.2833 13 16C13 15.7167 12.9042 15.4792 12.7125 15.2875C12.5208 15.0958 12.2833 15 12 15C11.7167 15 11.4792 15.0958 11.2875 15.2875C11.0958 15.4792 11 15.7167 11 16C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17ZM11 13H13V7H11V13ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                      fill="#BD791B"
                    />
                  </g>
                </svg>
                <p>
                  <strong>Você não possui uma assinatura ativa.</strong>{" "}
                </p>
              </div>
            </div>
          </BoxUserAccount>
        )
      ) : showSkeleton ? (
        <>
          <Skeleton width={"218.422px"} height={"22.406px"} />
          <Skeleton width={"800px"} height={"102.406px"} />
        </>
      ) : null}
      {dataAfterFirstRender ? (
        dataAfterFirstRender.interval ? (
          dataAfterFirstRender.cancel_at_period_end ? (
            <BoxUserAccount>
              <Link style={{ textAlign: "center", width: "100%" }} to="/planos">
                Assinar o plano Premium ou Premium Plus
              </Link>
            </BoxUserAccount>
          ) : (
            <BoxUserAccount>
              <Link
                style={{ color: "#A11722", textAlign: "center", width: "100%" }}
                to="#"
                onClick={popUpCancel}
              >
                Cancelar assinatura
              </Link>
            </BoxUserAccount>
          )
        ) : (
          <BoxUserAccount>
            <Link style={{ textAlign: "center", width: "100%" }} to="/planos">
              Assinar o plano Premium ou Premium Plus
            </Link>
          </BoxUserAccount>
        )
      ) : showSkeleton ? (
        <Skeleton width={"800px"} height={"70.406px"} />
      ) : null}
      {popUpshow && (
        <div className="popUpCancel">
          <svg
            onClick={popUpCancelFalse}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g clipPath="url(#clip0_3413_1262)">
              <path
                d="M8.88393 8.00027L15.8169 1.06722C16.061 0.823125 16.061 0.427376 15.8169 0.183314C15.5729 -0.0607479 15.1771 -0.0607792 14.933 0.183314L7.99999 7.11636L1.06697 0.183314C0.822881 -0.0607792 0.427132 -0.0607792 0.18307 0.183314C-0.0609921 0.427407 -0.0610233 0.823157 0.18307 1.06722L7.11609 8.00024L0.18307 14.9333C-0.0610233 15.1774 -0.0610233 15.5731 0.18307 15.8172C0.305101 15.9392 0.465069 16.0002 0.625038 16.0002C0.785006 16.0002 0.944944 15.9392 1.06701 15.8172L7.99999 8.88417L14.933 15.8172C15.055 15.9392 15.215 16.0002 15.375 16.0002C15.5349 16.0002 15.6949 15.9392 15.8169 15.8172C16.061 15.5731 16.061 15.1773 15.8169 14.9333L8.88393 8.00027Z"
                fill="#1B2CC1"
              />
            </g>
            <defs>
              <clipPath id="clip0_3413_1262">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div className="flexTittleButton">
            <h1>Tem certeza que deseja cancelar sua assinatura?</h1>
            <div className="flexButtons">
              <button onClick={popUpCancelFalse}>Voltar</button>
              <button onClick={cancelamento}>Cancelar Assinatura</button>
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div className="sameSizePopUpCancel">
          <Loader />
        </div>
      )}

      {sucessCancel && (
        <div className="sucessefulCancel">
          <svg
            onClick={sucessCancelFalse}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g clipPath="url(#clip0_3413_1262)">
              <path
                d="M8.88393 8.00027L15.8169 1.06722C16.061 0.823125 16.061 0.427376 15.8169 0.183314C15.5729 -0.0607479 15.1771 -0.0607792 14.933 0.183314L7.99999 7.11636L1.06697 0.183314C0.822881 -0.0607792 0.427132 -0.0607792 0.18307 0.183314C-0.0609921 0.427407 -0.0610233 0.823157 0.18307 1.06722L7.11609 8.00024L0.18307 14.9333C-0.0610233 15.1774 -0.0610233 15.5731 0.18307 15.8172C0.305101 15.9392 0.465069 16.0002 0.625038 16.0002C0.785006 16.0002 0.944944 15.9392 1.06701 15.8172L7.99999 8.88417L14.933 15.8172C15.055 15.9392 15.215 16.0002 15.375 16.0002C15.5349 16.0002 15.6949 15.9392 15.8169 15.8172C16.061 15.5731 16.061 15.1773 15.8169 14.9333L8.88393 8.00027Z"
                fill="#1B2CC1"
              />
            </g>
            <defs>
              <clipPath id="clip0_3413_1262">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div className="flexContent">
            <div className="flexTittleText">
              <h1>
                Sua assinatura do plano{" "}
                {dataAfterFirstRender.interval === "month"
                  ? "Premium"
                  : "Premium Plus"}{" "}
                foi cancelada.
              </h1>
              <p>
                Lamentamos que você esteja saindo. Lembre-se de que, mesmo sem a
                assinatura, você ainda pode acompanhar nossas notícias de forma
                gratuitas. <br />
                <br /> Se mudar de ideia, estaremos sempre prontos para te
                receber de volta!
                <br />
                <br /> Bons estudos e sucesso na sua jornada!
              </p>
            </div>
            <button onClick={sucessCancelFalse}>Fechar</button>
          </div>
        </div>
      )}
    </StyledUserAccount>
  );
}

export default UserAccountAssinatura;
