import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import Loader from "../UI/Loader";

function ProtectRouteLogin({ isAuthenticated }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isAuthenticated !== null) {
      setLoading(false);
    }
  }, [isAuthenticated]);

  if (loading) {
    return <Loader />;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(ProtectRouteLogin);
