import styled from "styled-components";

export const StyledWhiteNav = styled.nav`
  position: fixed;
  width: 100%;
  background-color: #fff;
  display: flex;
  padding: 14px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: var(--NavHeight);
  border-bottom: ${(props) =>
    props.line === "exist" ? "2px solid #EDEEFA" : "none"};

  svg {
    cursor: pointer;
  }
`;
