import styled from "styled-components";

export const StyledConcursos = styled.main`
  margin: 2rem auto 0 auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .CardMenu {
    margin-left: 1.067rem;
    .tituloPrincipal {
      color: var(--Grey-500);
      font-family: "Work Sans", sans-serif;
      font-size: 3.2rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: -1.44px;
      width: fit-content;
    }
    .cardsGrid {
      padding: 3.2rem 1.0667rem 0rem 1.0667rem;
      display: grid;
      grid-template: repeat(2, 57.9rem) / repeat(auto-fill, minmax(32rem, 1fr));
      row-gap: 2rem;
      column-gap: 2rem;
    }
    .cardConcursoGrid {
      display: grid;
      padding: 3.2rem 1.0667rem 0rem 1.0667rem;
      grid-template: repeat(2, 50.3125rem) / repeat(
          auto-fill,
          minmax(32rem, 1fr)
        );
      row-gap: 2rem;
      column-gap: 2rem;
    }
  }
  .LoaderElement {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .loader {
      display: inline-block;
      font-size: 48px;
      color: var(--Grey-500);
      font-family: "Work Sans", sans-serif;
      font-weight: 300;
      position: relative;
    }
    .loader::before {
      content: "";
      position: absolute;
      left: 90%;
      transform: translateX(-100%);
      bottom: 8px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 5px solid #fff;
      border-bottom-color: var(--Primary-500);
      box-sizing: border-box;
      animation: rotation 0.6s linear infinite;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  @media (max-width: 969px) {
    .SectionCard {
      grid-template: 1fr auto 45px / 100% !important;
      gap: 3rem;

      .CardMenu {
        grid-column: 1/2;
      }
    }
  }
  @media (max-width: 485px) {
    .CardMenu {
      .cardConcursoGrid {
        grid-template: repeat(2, 50.3125rem) / repeat(
            auto-fill,
            minmax(28rem, 1fr)
          );
      }
    }
  }
`;
