import { StyledCard } from "../styles/Card";
import CardSticky from "../UI/CardSticky";
import Tag from "../UI/Tag";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toTitleCase } from "../tools/functions";

// import lock from "../assets/lock.svg";
import {
  convertMoney,
  conversaoData,
  conversaoDia,
  conversaoSalario,
  compararDatasNome,
  compararDatasClasse,
} from "../tools/functions";

function Card({
  id,
  working_hours,
  diaDaProva,
  stage,
  icone,
  prefeitura,
  nomeDoCargo,
  vagas,
  banca,
  salario,
  inscricoesAbertas,
  inscricoesEncerradas,
  taxa,
  local,
  setWhatCard,
  tags,
}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setWhatCard({
      id: id,
    });
  }, []);

  const lista_de_datas = conversaoData(diaDaProva).split("/");

  return (
    <StyledCard className="Card">
      {/* 1 parte - Tags */}
      <div className="flexTags">
        <Tag
          nome={compararDatasNome(inscricoesAbertas, inscricoesEncerradas)}
          classname={compararDatasClasse(
            inscricoesAbertas,
            inscricoesEncerradas
          )}
        />
      </div>
      {/* 2 parte - imagem e local */}
      <div className="imageAndLocal">
        {/* <IconeSVG svg={icone} cor={"red"}/> */}
        <img src={icone} alt="icone" />
        {/* <svg xmlns={icone}></svg> */}
        <h4>{prefeitura}</h4>
      </div>
      {/* 3 parte - Nome e Superior selecionados */}
      <div className="flexNameAndSuperior">
        <h2 className="contestName">{toTitleCase(nomeDoCargo)}</h2>
        <div className="flexSuperior">
          {/* <p className='superiorSelected'>superior selecionados</p> */}
          {tags
            ? tags.map((item, index) => {
                return (
                  <Tag key={index} nome={item.name} classname={"tagNormal"} />
                );
              })
            : null}
          {/* tag de momento do concurso */}
          {/* edital publicado, inscrições abertas, inscrições encerradas */}
          {/* <Icone imagem={error1}/> */}
        </div>
      </div>
      {/* 4 parte - TripleData */}
      <div className="tripleData">
        <CardSticky
          titulo={vagas === 0 ? "CR" : vagas}
          subTitulo={vagas === 1 ? "Vaga" : vagas === 0 ? "vagas" : "Vagas"}
          balao={vagas === 0 ? "Cadastro de Reserva" : null}
        />
        {/* <CardSticky titulo={banca} subTitulo={"Banca"}/> */}
        <CardSticky titulo={working_hours} subTitulo={"Horas semanais"} />
        <CardSticky
          titulo={`${conversaoSalario(salario)}K+`}
          subTitulo={"Salário"}
        />
      </div>
      {/* 5 parte - miscData */}
      <div className="miscData">
        <h4>
          <strong>Banca: </strong>
          {banca}
        </h4>
        <h4>
          <strong>Inscrições: </strong>
          {conversaoDia(inscricoesAbertas)} a{" "}
          {conversaoData(inscricoesEncerradas)}
        </h4>
        <h4>
          <strong>Taxa: </strong>
          {`${convertMoney(taxa)}`}
        </h4>
        <h4>
          <strong>Prova: </strong>
          {`${lista_de_datas[0]}/${lista_de_datas[1]}/${lista_de_datas[2]}`}
        </h4>
        <div className="misclocation">
          <h4>
            <strong>Local: </strong>
            {local}
          </h4>
        </div>
      </div>
      {/* 6 parte - Botão */}
      <Link to={`/concurso/${id}`}>
        <button className="confiraConcurso">
          <p>Confira o concurso </p>
        </button>
      </Link>
    </StyledCard>
  );
}

export default Card;
