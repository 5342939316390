import styled from "styled-components";

export const StyledFilter = styled.aside`
  .filterHide {
    display: none;
  }
  .filterClean {
    margin: 1rem 0 0.5rem;
    background-color: #fff;
    padding: 0.4rem 1rem;
    color: var(--Primary-500);
    font-family: "Work Sans", sans-serif;
    border-radius: 0.4rem;
    border-width: 0.1rem;
    border-color: var(--Primary-500);
    border-style: solid;
    font-size: 1.6rem;
    cursor: pointer;
  }
  .flex {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 2rem;
    color: #474747;
    font-family: "Work Sans", sans-serif;

    img {
      width: 1.5rem;
    }

    h3 {
      font-weight: normal;
    }
  }
  @media (max-width: 969px) {
    width: 100%;
    .gridFilterMobile {
      position: relative;
      display: grid;
      grid-template: 1fr / 1fr 1fr;
      column-gap: 3.6rem;
      row-gap: 2.4rem;
    }
  }
  @media (max-width: 485px) {
    .gridFilterMobile {
      grid-template: 1fr / 1fr;
    }
  }
`;
