import styled from "styled-components";

export const StyledInnerCard = styled.main`
  display: flex;
  flex-direction: column;
  gap: var(--Scale-5);
  /* width: 70%; */
  /* margin: var(--Scale-12) auto; */
  max-width: 134.4rem;
  margin: 7.2rem auto 9.6rem auto !important;

  .flexHeader {
    display: flex;
    flex-direction: column;
    gap: var(--Scale-6, 48px);
    margin-bottom: 0.8rem;
  }
  .sectionRelative {
    position: relative;
  }

  .tittle {
    display: flex;
    flex-direction: column;
    gap: var(--Scale-2);
    .flexTittle {
      display: flex;
      gap: var(--Scale-4);
      align-items: center;
      .mobileLogo {
        display: none;
      }
      h1 {
        color: var(--Grey-900, #252a3d);
        /* Title/Large/Regular */
        font-family: "Work Sans";
        font-size: 3.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.72px;
      }
      img {
        align-self: center;
        height: 3.2rem;
      }
    }
  }
  .flexFigures {
    display: grid;
    grid-template-columns: 350px 128px 237px 247px 170px;
    gap: 8px;
  }
  .flexTittleStates {
    display: flex;
    flex-direction: column;
    gap: var(--Scale-2, 16px);
    height: var(--start-height);
    overflow: hidden;
    transition: height 0.5s ease;

    .tittle {
      color: var(--Grey-700, #33394d);
      font-family: "Work Sans";
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.16px;
    }
    .flexListStates {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
      .flexStateCity {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        max-height: 5.2766rem;

        .flexState {
          display: flex;
          align-items: center;
          gap: var(--Scale-2, 16px);

          .stateName {
            color: var(--Grey-700, #33394d);
            font-family: "Work Sans";
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: 0.16px;
          }
        }
        .city {
          color: var(--Grey-500, #606780);
          font-family: "Work Sans";
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          letter-spacing: 0.16px;
        }
      }
    }
  }

  .officeSection {
    display: flex;
    flex-direction: column;
    gap: var(--Scale-2, 16px);

    h2 {
      color: var(--Grey-700, #33394d);
      font-family: "Work Sans";
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.16px;
    }

    p {
      color: var(--Grey-500, #606780);
      font-family: "Work Sans";
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.16px;
      max-width: 100%;
      word-wrap: break-word;
    }
  }
  .showMoreButton {
    color: var(--Primary-500, #1b2cc1);
    font-family: "Work Sans";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.16px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: fit-content;
  }

  @keyframes expand {
    from {
      height: var(--start-height);
    }
    to {
      height: var(--end-height);
    }
  }

  @keyframes desexpand {
    from {
      height: var(--end-height);
    }
    to {
      height: var(--start-height);
    }
  }

  .expand {
    animation: expand var(--animation-time) forwards;
  }

  .desexpand {
    animation: desexpand var(--animation-time) forwards;
  }

  .notices {
    h2 {
      color: var(--Grey-700, #33394d);
      font-family: "Work Sans";
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.16px;
    }

    .notice {
      display: flex;
      align-items: center;
      gap: 1.6rem;
      padding: 1.2rem var(--Scale-2, 16px) 1.2rem var(--Scale-0, 0px);
    }

    .pdfLink {
      color: var(--Primary-500, #1b2cc1);
      text-align: center;
      text-decoration: none;

      /* Text/Large/Regular */
      font-family: "Work Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.16px;
      :visited {
        color: var(--Primary-500, #1b2cc1);
      }
    }
  }

  @media (max-width: 1300px) {
    .flexFigures {
      grid-template-columns: 350px 128px 237px;
    }
    .gridItems5 {
      grid-column: 2/4;
    }
  }

  @media (max-width: 823px) {
    .flexFigures {
      grid-template-columns: 350px 237px;
      grid-template-rows: repeat(3, 1fr);
    }
  }

  @media (max-width: 671px) {
    .flexFigures {
      grid-template-columns: 350px;
    }
  }
  @media (max-width: 540px) {
    .flexTittle {
      flex-direction: column;
      gap: 16px !important;
      align-items: start !important;
      img {
        align-self: start !important;
      }
      .desktopLogo {
        display: none;
      }

      h1 {
        overflow-wrap: break-word;
      }
    }
    .mobileLogo {
      display: block !important;
    }
  }
  .textoDeMeioDeTela {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3.2rem;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.16px;
  }

  @media (max-width: 1390px) {
    padding: 0 3.2rem;
  }
  @media (max-width: 400px) {
    .flexFigures {
      grid-template-columns: 300px;
    }
  }
`;
