import React from "react";
import { StyledHomepageStatistics } from "../styles/homepageStatistics";

function HomepageStatistics({ tittle, subttittle, text, rs }) {
  return (
    <StyledHomepageStatistics>
      <div className="flexCardStatistics">
        <div className="flexTittleText">
          {rs && <p>R$</p>}
          <h3>{tittle}</h3>
        </div>
        <h4>{subttittle}</h4>
        <p>{text}</p>
      </div>
    </StyledHomepageStatistics>
  );
}

export default HomepageStatistics;
