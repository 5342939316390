import styled from "styled-components";

export const StyledFilterHide = styled.div`
  display: none;
  @media (max-width: 969px) {
    position: relative;
    display: flex;
    width: 100%;
    padding: var(--Scale-3, 24px) 16px;
    align-items: flex-start;
    gap: 10px;
    background: var(--Grey-50, #f7f8ff);
    justify-content: center;
    align-items: center;
    img {
      width: 2.1rem;
    }
    p {
      color: var(--Primary-500, #1b2cc1);

      /* Text/Large/Regular */
      font-family: "Work Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.16px;
    }
  }
`;
