import styled from "styled-components";

export const StyledTag = styled.label`
  // margin: 0 auto 0 0;
  width: fit-content;
  height: fit-content;
  display: flex;
  padding: 0.4rem 0.8rem;
  justify-content: center;
  align-items: center;
  gap: 8px;
  // border-radius: $--Radius-3xl;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.15);
  text-transform: capitalize;

  p {
    text-align: center;
    font-family: "Work Sans", sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 150%;
    text-transform: lowercase;
  }
  img {
    width: 1.6rem;
    height: 1.6rem;
  }
  .concursoPrevisto {
    background-color: var(--Grey-50);
    color: var(--Grey-600);
  }

  .transparente {
    background-color: transparent;
  }
`;
