import styled from "styled-components";

export const StyledActivate = styled.main`
  background-color: var(--Primary-100, #e6e5ff);
  min-height: calc(100vh - var(--NewFooterHeight) - var(--NavHeight));
  .oldActivate {
    display: flex;
    width: 42.4rem;
    padding: 4.8rem 3.2rem;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    margin: 9.6rem auto;
    border-radius: 1.6rem;
    background: var(--Primary-25, #f2f3ff);

    /* Drop shadow/sm */
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
    .flexTittle {
      display: flex;
      flex-direction: column;
      gap: 3.2rem;
      h1 {
        color: var(--Grey-700, #33394d);
        text-align: center;

        /* Title/Medium/Bold */
        font-family: "Work Sans";
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 24px */
      }
      p {
        color: var(--Grey-500, #606780);
        text-align: center;

        /* Text/Large/Regular */
        font-family: "Work Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.16px;
        width: 21.6rem;
      }
    }

    button {
      width: 100%;
      display: flex;
      height: 56px;
      padding: 20px 32px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 6px;
      border: none;
      background: var(--Primary-500, #1b2cc1);
      color: var(--Primary-050, #f0fdf4);
      font-family: "Work Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 175% */
      cursor: pointer;
    }

    .checkmark {
      width: 9.6rem;
      height: 9.6rem;
      align-self: flex-start;
    }
    .flexTittleText {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Scale-4, 3.2rem);
      h1 {
        color: var(--Grey-700, #33394d);

        /* Title/Medium/Bold */
        font-family: "Work Sans";
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 24px */
      }
      p {
        color: var(--Grey-500, #606780);

        /* Text/Large/Regular */
        font-family: "Work Sans";
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.16px;
      }
    }
  }
  .redirectWarning {
    max-width: 28rem;
    margin: 0 auto;
    text-align: center;
    p {
      color: var(--Grey-500, #606780);

      /* Text/Medium/Regular */
      font-family: "Work Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    a {
      text-decoration: none;
      color: var(--Primary-500, #1b2cc1);
      text-align: center;

      /* Text/Medium/Regular */
      font-family: "Work Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
    }
  }
  @media (max-width: 465px) {
    .oldActivate {
      width: 36rem;
    }
  }
  @media (max-width: 390px) {
    .oldActivate {
      width: 33rem;
    }
  }
`;
