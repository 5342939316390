import { React, useState, useEffect } from "react";
import { StyledSecondSearchBar } from "../styles/SecondSearchBar";
import logoHeader from "../assets/logoHeader.svg";
import lupa from "../assets/lupa.svg";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import Skeleton from "../UI/Skeleton";
import SelectInput from "../UI/SelectInput";
import { useLocation } from "react-router-dom";

function SecondSearchBar({
  page,
  setTermoPesquisado,
  termoPesquisado,
  setSearchReset,
  searchReset,
  width,
}) {
  const [userInput, setUserInput] = useState("");
  const [placeholder, setPlaceholder] = useState("Pesquise por um cargo");
  const [selected, setSelected] = useState(page);
  const [skeleton, setSkeleton] = useState(false);
  const navigate = useNavigate();
  let encodedSearchTerm = "";
  const [imageIsLoaded, setImageIsLoaded] = useState(false);
  const [inputIsLoaded, setInputIsLoaded] = useState(false);

  const location = useLocation();
  const pathname = decodeURIComponent(location.pathname);

  useEffect(() => {
    if (
      selected === "Cargos" ||
      selected === "Homepage" ||
      selected === "Blog"
    ) {
      setPlaceholder("Pesquise por um cargo");
    } else if (selected === "Concursos") {
      setPlaceholder("Pesquise por um concurso");
    }
  }, [selected]);

  const handleImageLoad = () => {
    setImageIsLoaded(true);
  };

  useEffect(() => {
    if (pathname.includes("/cargos")) {
      setTermoPesquisado(pathname.split("/")[2]);
    } else if (pathname.includes("/concursos")) {
      setTermoPesquisado(pathname.split("/")[2]);
    }
  }, [pathname, setTermoPesquisado]);

  useEffect(() => {
    setTimeout(() => {
      setSkeleton(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (termoPesquisado) {
      setUserInput(termoPesquisado);
    }
  }, [termoPesquisado]);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 900) {
        if (
          selected === "Cargos" ||
          selected === "Homepage" ||
          selected === "Blog"
        ) {
          setPlaceholder("Exemplo: Auditor");
        } else {
          setPlaceholder("Exemplo: ANAC");
        }
      } else {
        // Manter o comportamento anterior para os outros tamanhos
        if (
          selected === "Cargos" ||
          selected === "Homepage" ||
          selected === "Blog"
        ) {
          setPlaceholder("Pesquise por um cargo");
        } else if (selected === "Concursos") {
          setPlaceholder("Pesquise por um concurso");
        }
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [selected]);

  function capturingSearchTerm(term) {
    ReactGA.event({
      category: page === "Cargos" ? "Search in offices" : "Search in contests",
      action: "search_term",
      label: term,
    });
  }

  const searchFunction = (e) => {
    e.preventDefault();
    if (userInput === "") {
      setTimeout(() => {
        setPlaceholder("Por favor digite algo");
      }, 1000);
    } else {
      if (
        selected === "Cargos" ||
        selected === "Homepage" ||
        selected === "Blog"
      ) {
        capturingSearchTerm(userInput);
        encodedSearchTerm = encodeURIComponent(userInput);
        setTermoPesquisado(userInput);
        navigate(`/cargos/${encodedSearchTerm}`);
      } else {
        capturingSearchTerm(userInput);
        encodedSearchTerm = encodeURIComponent(userInput);
        setTermoPesquisado(userInput);
        navigate(`/concursos/${encodedSearchTerm}`);
      }
    }

    setSearchReset(!searchReset);
  };

  function navegationPage(page) {
    navigate(page);
  }
  function Load() {
    setInputIsLoaded(true);
  }
  function updateText(e) {
    setUserInput(e.target.value);
  }

  return (
    <StyledSecondSearchBar
      style={{ minWidth: width, maxWidth: width }}
      onSubmit={searchFunction}
      className="SecondSearchBar"
    >
      {page === "Homepage" || page === "Blog" ? null : !imageIsLoaded ? (
        skeleton ? (
          <Skeleton width={"20rem"} height={"5.5719rem"} />
        ) : null
      ) : null}

      {page === "Homepage" || page === "Blog" ? null : !inputIsLoaded ? (
        skeleton ? (
          <Skeleton width={"143.8690rem"} height={"5.3rem"} />
        ) : null
      ) : (
        <img
          className="logoSearch"
          src={logoHeader}
          alt="logo"
          onClick={() => navegationPage("/")}
          onLoad={handleImageLoad}
        />
      )}
      <div
        style={{
          display: inputIsLoaded ? "flex" : "none",
          minWidth: width,
        }}
        onLoad={Load}
        className="flexSearch"
      >
        <SelectInput
          defaultValue={
            page === "Homepage" || page === "Blog" ? "Cargos" : page
          }
          setSelected={setSelected}
        />

        <div className="pipe">|</div>
        <div className="flexInput">
          <input
            value={userInput}
            onChange={(e) => updateText(e)}
            type="text"
            placeholder={placeholder}
          />
        </div>
        <div className="pipe">|</div>
        <button type="submit" className="lupa">
          <img src={lupa} alt="lupa" />
        </button>
      </div>
    </StyledSecondSearchBar>
  );
}

export default SecondSearchBar;
