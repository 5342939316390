import React from "react";
import { StyledWhiteNav } from "../styles/WhiteNav";
import { useNavigate } from "react-router-dom";
import logoBlog from "../assets/logoBlog.png";

function WhiteNav({ page, line }) {
  const navigate = useNavigate();
  return (
    <StyledWhiteNav line={line}>
      {page === "blog" ? (
        <img
          onClick={() => navigate("/blog")}
          style={{ width: "176px", height: "38px", cursor: "pointer" }}
          src={logoBlog}
          alt="Logo Blog"
        />
      ) : (
        <svg
          onClick={() => navigate("/")}
          xmlns="http://www.w3.org/2000/svg"
          width="144"
          height="40"
          viewBox="0 0 144 40"
          fill="none"
        >
          <path
            d="M49.4092 15.6544C49.4092 11.17 52.374 8.57141 55.7891 8.57141C57.5356 8.57141 58.945 9.39438 59.8523 10.3243L58.2419 12.2846C57.5647 11.6528 56.8357 11.2089 55.8572 11.2089C54.0038 11.2089 52.5392 12.8419 52.5392 15.5539C52.5392 18.2659 53.8321 19.9476 55.8086 19.9476C56.9167 19.9476 57.7915 19.4 58.4525 18.6839L60.0629 20.6021C58.9418 21.9079 57.4481 22.5851 55.6984 22.5851C52.2703 22.5851 49.4092 20.2101 49.4092 15.6576V15.6544Z"
            fill="#5069A3"
          />
          <path
            d="M61.2651 17.1938C61.2651 13.7722 63.6725 11.7989 66.2744 11.7989C68.8762 11.7989 71.2869 13.7754 71.2869 17.1938C71.2869 20.6121 68.8795 22.5821 66.2744 22.5821C63.6693 22.5821 61.2651 20.6056 61.2651 17.1938ZM68.1634 17.1938C68.1634 15.4149 67.538 14.2582 66.2744 14.2582C65.0107 14.2582 64.3886 15.4149 64.3886 17.1938C64.3886 18.9726 65.014 20.1229 66.2744 20.1229C67.5348 20.1229 68.1634 18.9758 68.1634 17.1938Z"
            fill="#5069A3"
          />
          <path
            d="M73.3702 12.0455H75.8554L76.0757 13.3481H76.1502C77.0154 12.5153 78.0522 11.796 79.4552 11.796C81.6909 11.796 82.6467 13.374 82.6467 15.9467V22.333H79.5978V16.3355C79.5978 14.8547 79.1992 14.3914 78.3211 14.3914C77.5565 14.3914 77.0866 14.7478 76.4159 15.3893V22.3298H73.3669V12.0423L73.3702 12.0455Z"
            fill="#5069A3"
          />
          <path
            d="M84.633 17.1938C84.633 13.7722 87.1506 11.7989 89.9922 11.7989C91.2591 11.7989 92.2312 12.2493 92.9991 12.907L91.5605 14.8673C91.068 14.4493 90.6565 14.2614 90.1672 14.2614C88.7026 14.2614 87.7598 15.4182 87.7598 17.197C87.7598 18.9759 88.7415 20.1261 90.0635 20.1261C90.7375 20.1261 91.3855 19.7924 91.9201 19.3679L93.1125 21.3606C92.1113 22.2452 90.8347 22.5854 89.7201 22.5854C86.8493 22.5854 84.633 20.6089 84.633 17.197V17.1938Z"
            fill="#5069A3"
          />
          <path
            d="M94.8167 18.4306V12.0443H97.8657V18.0418C97.8657 19.5225 98.2643 19.9859 99.1423 19.9859C99.907 19.9859 100.377 19.6651 100.97 18.8551V12.0475H104.019V22.335H101.534L101.313 20.9093H101.239C100.38 21.9332 99.4113 22.5845 98.0083 22.5845C95.7726 22.5845 94.8167 21.0065 94.8167 18.4338V18.4306Z"
            fill="#5069A3"
          />
          <path
            d="M106.695 12.0455H109.18L109.401 13.8438H109.475C110.224 12.47 111.348 11.796 112.398 11.796C112.984 11.796 113.328 11.8705 113.626 12.0034L113.117 14.6344C112.751 14.5437 112.45 14.4692 112.006 14.4692C111.231 14.4692 110.288 14.9681 109.741 16.3711V22.333H106.692V12.0455H106.695Z"
            fill="#5069A3"
          />
          <path
            d="M113.872 21.1471L115.243 19.2484C116.176 19.9547 117.035 20.3274 117.897 20.3274C118.804 20.3274 119.196 19.9807 119.196 19.4428C119.196 18.7656 118.175 18.4578 117.119 18.0431C115.859 17.557 114.397 16.7178 114.397 15.0168C114.397 13.1018 115.952 11.796 118.328 11.796C119.905 11.796 121.078 12.4311 121.953 13.0986L120.589 14.9293C119.857 14.3979 119.134 14.0512 118.421 14.0512C117.634 14.0512 117.245 14.359 117.245 14.8742C117.245 15.5287 118.208 15.7814 119.274 16.1702C120.576 16.653 122.044 17.3853 122.044 19.2646C122.044 21.1439 120.576 22.5825 117.819 22.5825C116.461 22.5825 114.912 21.996 113.869 21.1471H113.872Z"
            fill="#5069A3"
          />
          <path
            d="M123.369 17.1938C123.369 13.7722 125.777 11.7989 128.378 11.7989C130.98 11.7989 133.391 13.7754 133.391 17.1938C133.391 20.6121 130.984 22.5821 128.378 22.5821C125.773 22.5821 123.369 20.6056 123.369 17.1938ZM130.271 17.1938C130.271 15.4149 129.645 14.2582 128.382 14.2582C127.118 14.2582 126.496 15.4149 126.496 17.1938C126.496 18.9726 127.121 20.1229 128.382 20.1229C129.642 20.1229 130.271 18.9758 130.271 17.1938Z"
            fill="#5069A3"
          />
          <path
            d="M134.463 21.1471L135.834 19.2484C136.767 19.9547 137.626 20.3274 138.488 20.3274C139.395 20.3274 139.787 19.9807 139.787 19.4428C139.787 18.7656 138.766 18.4578 137.71 18.0431C136.45 17.557 134.988 16.7178 134.988 15.0168C134.988 13.1018 136.543 11.796 138.919 11.796C140.496 11.796 141.669 12.4311 142.544 13.0986L141.18 14.9293C140.448 14.3979 139.725 14.0512 139.012 14.0512C138.225 14.0512 137.836 14.359 137.836 14.8742C137.836 15.5287 138.799 15.7814 139.865 16.1702C141.167 16.653 142.635 17.3853 142.635 19.2646C142.635 21.1439 141.167 22.5825 138.41 22.5825C137.052 22.5825 135.503 21.996 134.46 21.1471H134.463Z"
            fill="#5069A3"
          />
          <path
            d="M42.8188 22.5655C42.8188 26.4277 39.7892 29.5578 36.0501 29.5578H23.8412L16.4244 36.6861L13.4727 29.5578H9.03687C5.29773 29.5578 2.26817 26.4277 2.26817 22.5655V9.7312C2.26817 5.86893 5.29773 2.73895 9.03687 2.73895H36.0501C39.7892 2.73895 42.8188 5.86893 42.8188 9.7312"
            stroke="#5FCE1D"
            strokeWidth="3.56417"
            strokeMiterlimit="10"
          />
          <path
            d="M9.39627 20.5013C9.39627 18.3336 11.12 17.1607 15.1897 16.7297C15.1281 15.8289 14.681 15.2425 13.5631 15.2425C12.7012 15.2425 11.8394 15.6054 10.8414 16.1724L9.75916 14.17C11.0649 13.3729 12.536 12.8156 14.1528 12.8156C16.7838 12.8156 18.2387 14.319 18.2387 17.4717V23.3526H15.7535L15.5331 22.2996H15.4586C14.5935 23.061 13.6214 23.6021 12.4647 23.6021C10.5887 23.6021 9.39951 22.2347 9.39951 20.5013H9.39627ZM15.1897 20.3652V18.5669C13.022 18.8585 12.3157 19.4774 12.3157 20.255C12.3157 20.9192 12.7725 21.2433 13.4951 21.2433C14.2176 21.2433 14.6486 20.903 15.1897 20.3619V20.3652Z"
            fill="#5069A3"
          />
          <path
            d="M20.7982 20.158V8.82715H23.8472V20.2811C23.8472 20.9356 24.1486 21.143 24.3981 21.143C24.5147 21.143 24.599 21.143 24.7707 21.1009L25.1336 23.3496C24.7966 23.4921 24.2976 23.6023 23.6042 23.6023C21.5143 23.6023 20.7982 22.2382 20.7982 20.158Z"
            fill="#5069A3"
          />
          <path
            d="M26.1317 18.2138C26.1317 14.7922 28.5391 12.8189 31.141 12.8189C33.7428 12.8189 36.1535 14.7954 36.1535 18.2138C36.1535 21.6321 33.7461 23.6022 31.141 23.6022C28.5359 23.6022 26.1317 21.6257 26.1317 18.2138ZM29.5889 7.92303H32.6995L34.9417 10.6415L33.7817 11.7238L31.1863 9.81532H31.1021L28.5067 11.7238L27.3468 10.6415L29.5889 7.92303ZM33.03 18.2138C33.03 16.4349 32.4046 15.2782 31.1442 15.2782C29.8838 15.2782 29.2585 16.4349 29.2585 18.2138C29.2585 19.9926 29.8838 21.1429 31.1442 21.1429C32.4046 21.1429 33.03 19.9959 33.03 18.2138Z"
            fill="#5069A3"
          />
        </svg>
      )}
    </StyledWhiteNav>
  );
}

export default WhiteNav;
