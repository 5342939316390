import styled from "styled-components";

export const StyledFlexMenu = styled.div`
  display: flex;
  height: var(--Scale-5, 40px);
  justify-content: center;
  align-items: center;
  gap: 1rem;

  img {
    width: 1.6rem;
    height: 1.6rem;
  }
  .active {
    color: var(--Grey-500, #606780);
    font-family: "Work Sans";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.16px;
    cursor: pointer;
    border: none;
    background: none;
  }
  .inactive {
    color: var(--Primary-500, #1b2cc1);
    font-family: "Work Sans";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.16px;
    cursor: pointer;
    border: none;
    background: none;
  }

  @media (max-width: 970px) {
    align-self: center;
  }
`;
