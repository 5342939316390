import React from "react";
import { StyledSkeleton } from "../styles/Skeleton";

function Skeleton({ width, height, additionalStyle }) {
  return (
    <StyledSkeleton
      style={{
        minWidth: width,
        maxWidth: width,
        height: height,
        ...additionalStyle,
      }}
    ></StyledSkeleton>
  );
}

export default Skeleton;
