import styled from "styled-components";

export const StyledPopUpNew = styled.button`
  width: 285px;
  height: 57px;
  border: none;
  display: flex;
  z-index: 997;
  position: absolute;
  border-radius: 8px;
  background: var(--Grey-900, #252a3d);
  box-shadow: 0px 0px 64px 0px rgba(37, 42, 61, 0.25);
  padding: var(--Scale-2, 16px) var(--Scale-4, 32px);
  flex-direction: column;
  justify-content: center;
  gap: var(--Scale-3, 24px);
  transform: translateY(calc(-50% - 12px));
  transition: all 0.5s ease;
  p {
    color: var(--Grey-25, #fcfdff);

    /* Text/Extra Large/Regular */
    font-family: "Work Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.18px;
    span {
      color: var(--Primary-200, #aaaaf0);
      font-family: "Work Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 25.2px */
      letter-spacing: 0.18px;
      text-decoration-line: underline;
    }
    a {
      cursor: pointer;
      color: var(--Primary-200, #aaaaf0);
      &:visited {
        color: var(--Primary-200, #aaaaf0);
      }
    }
  }
  @media (max-width: 680px) {
    left: 50%;
    top: 50% !important;
    transform: translate(-50%, -50%);
  }
`;
