import styled from "styled-components";

export const StyledFooterShort = styled.footer`
  background-color: var(--Grey-900);
  .final {
    /* border-top: 1px solid #495066; */
    display: flex;
    padding: var(--Scale-3, 24px) var(--Radius-md, 8px);
    justify-content: center;
    align-items: center;
    gap: var(--Radius-md, 8px);

    p {
      color: var(--Grey-300, #a0a5bd);
      width: 1440px;
      /* Text/Large/Regular */
      font-family: "Work Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.16px;
    }
  }
`;
