import styled from "styled-components";

export const StyledSkeleton = styled.div`
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  position: relative;
  background-color: #ccc;
  border-radius: 8px;
  animation: pulse 1.5s infinite;
`;
