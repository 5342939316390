import React from "react";
import { StyledInnerPostBlog } from "../styles/InnerPostBlog";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { conversaoDataMes } from "../tools/functions";
function InnerPostBlog({ urlAtual }) {
  const { id } = useParams();
  const dataPost = {
    id: 1,
    main_image:
      "https://www.ibirapuera.br/wp-content/uploads/2020/12/MESTRADO-OU-DOUTORADO.jpg",
    main_image_description: "Mulher lendo livros em uma biblioteca",
    tittle:
      "Explorando as últimas pesquisas: estudos chave que estão moldando nossa compreensão em 2024",
    lastUpdate: "2024-11-28T00:00:00Z",
    article: [
      {
        tittle: "Introduction",
        content:
          "Drawing is one of the oldest forms of human expression, dating back to prehistoric times. It is a fundamental skill for artists and serves as a foundation for other visual arts such as painting and sculpture. In this article, we will explore various drawing techniques and tips to help you improve your skills and create stunning artwork. Whether you are a beginner or an experienced artist, there is always something new to learn and discover in the world of drawing.",
        image:
          "https://staging.aloconcursos.com/static/media/historia.47b8952954a26f227e08.png",
        image_description: "Mulher lendo livros em uma biblioteca",
      },
      {
        tittle: "Techniques",
        content:
          "There are numerous drawing techniques that artists use to create different effects and textures. Some of the most popular techniques include hatching, cross-hatching, stippling, and blending. Hatching involves drawing closely spaced parallel lines to create shading, while cross-hatching adds another layer of lines at an angle to the first. Stippling uses small dots to build up tone and texture, and blending involves smoothing out lines and shading to create a more realistic effect. Experimenting with these techniques can help you find your unique style and improve your drawing skills.",
      },
      {
        tittle: "Tips",
        content:
          "Here are some tips to help you get the most out of your drawing practice: 1. Start with simple shapes: Break down complex subjects into basic shapes to make them easier to draw. 2. Use references: Study photographs, real-life objects, and other artworks to improve your understanding of form and perspective. 3. Practice regularly: Consistent practice is key to improving your drawing skills. Set aside time each day to draw, even if it's just for a few minutes. 4. Experiment with different tools: Try using different pencils, pens, and papers to see how they affect your drawings. 5. Don't be afraid to make mistakes: Mistakes are a natural part of the learning process. Embrace them and use them as opportunities to learn and grow as an artist.",
      },
    ],
  };
  return (
    <StyledInnerPostBlog>
      <div className="flexBack">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="22"
          viewBox="0 0 23 22"
          fill="none"
        >
          <g clipPath="url(#clip0_3025_1590)">
            <path
              d="M0.831591 12.9158L4.37909 16.5C4.46431 16.5859 4.56569 16.6541 4.6774 16.7006C4.7891 16.7472 4.90891 16.7711 5.02992 16.7711C5.15094 16.7711 5.27075 16.7472 5.38245 16.7006C5.49416 16.6541 5.59554 16.5859 5.68076 16.5C5.76668 16.4148 5.83487 16.3134 5.88141 16.2017C5.92795 16.09 5.95191 15.9701 5.95191 15.8491C5.95191 15.7281 5.92795 15.6083 5.88141 15.4966C5.83487 15.3849 5.76668 15.2835 5.68076 15.1983L2.41742 11.9166H21.1083C21.3514 11.9166 21.5845 11.8201 21.7564 11.6482C21.9283 11.4762 22.0249 11.2431 22.0249 11C22.0249 10.7569 21.9283 10.5237 21.7564 10.3518C21.5845 10.1799 21.3514 10.0833 21.1083 10.0833H2.36242L5.68076 6.76497C5.84327 6.59449 5.93393 6.368 5.93393 6.13247C5.93393 5.89694 5.84327 5.67045 5.68076 5.49997C5.59554 5.41405 5.49416 5.34586 5.38245 5.29932C5.27075 5.25278 5.15094 5.22882 5.02992 5.22882C4.90891 5.22882 4.7891 5.25278 4.6774 5.29932C4.56569 5.34586 4.46431 5.41405 4.37909 5.49997L0.831591 9.02914C0.316606 9.54476 0.0273438 10.2437 0.0273438 10.9725C0.0273437 11.7012 0.316606 12.4002 0.831591 12.9158Z"
              fill="#1B2CC1"
            />
          </g>
          <defs>
            <clipPath id="clip0_3025_1590">
              <rect
                width="22"
                height="22"
                fill="white"
                transform="translate(0.0249023)"
              />
            </clipPath>
          </defs>
        </svg>
        <Link to={urlAtual}>Voltar</Link>
      </div>

      <section className="firstSection">
        <img src={dataPost.main_image} alt={dataPost.main_image_description} />
        <div className="flexTittle">
          <h1>{dataPost.tittle}</h1>
          <p>{`Atualizado em ${conversaoDataMes(dataPost.lastUpdate)}`}</p>
        </div>
      </section>
      <article>
        {dataPost.article.map((item, index) => {
          return (
            <div className="post">
              <h2>{item.tittle}</h2>
              <p>{item.content}</p>
              {item.image && (
                <img src={item.image} alt={item.image_description} />
              )}
            </div>
          );
        })}
      </article>
    </StyledInnerPostBlog>
  );
}

export default InnerPostBlog;
