import React from "react";
import { StyledNotFound } from "../styles/NotFound";
import { Link } from "react-router-dom";
function NotFound() {
  return (
    <StyledNotFound>
      <section>
        <div className="tittleSubtittle">
          <h2>Erro</h2>
          <h1>404</h1>
        </div>
        <div className="tittleText">
          <h3>A página não foi encontrada.</h3>
          <p>
            A página que você está tentando acessar não existe ou foi movida.
            <br />
            Tente voltar para a página inicial.
          </p>
        </div>
        <Link to="/">
          <button>Ir para a página inicial</button>
        </Link>
      </section>
    </StyledNotFound>
  );
}

export default NotFound;
