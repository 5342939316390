import styled from "styled-components";

export const StyledUserAccountConta = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Scale-3, 24px);

  h1 {
    color: var(--Grey-700, #33394d);
    font-family: "Work Sans";
    font-size: 3.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.72px;
  }
  h5 {
    color: var(--Grey-500, #606780);
    font-family: "Work Sans";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.16px;
  }
  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    .flexInfo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.2rem;
      .infoBox {
        width: 100%;
        display: flex;
        padding: var(--Scale-3, 24px);
        flex-direction: column;
        align-items: flex-start;
        gap: 1.6rem;
        border-radius: 16px;
        background: var(--Supporting-white, #fff);
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
        a {
          color: var(--Primary-500, #1b2cc1);
          font-family: "Work Sans";
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          letter-spacing: 0.16px;
          text-decoration-line: underline;
        }
        div {
          display: flex;
          gap: 0.8rem;
          span {
            color: var(--Grey-700, #33394d);
            font-family: "Work Sans";
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: 0.16px;
          }
          p {
            color: var(--Grey-700, #33394d);
            font-family: "Work Sans";
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: 0.16px;
          }
        }
      }
    }
  }
`;
