import { useEffect } from "react";
import { StyledPayment } from "../styles/Payment";
import CardPayment from "../components/CardPayment";
import { Basic, Premium, PremiumPlus } from "../tools/planos";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

function Payment({ setUrlAtual, setTipoDePlano }) {
  document.title = `Premium - Alô Concursos`;
  const location = useLocation();
  // Lógica pra capturar a página antes do login
  useEffect(() => {
    setUrlAtual(location.pathname);
  }, []);

  return (
    <StyledPayment className="PaymentPage">
      <section className="PaymentMenu">
        <div className="flexTittle">
          <h1>Não perca nenhuma oportunidade!</h1>
          <p>Assine agora e tenha acesso exclusivo.</p>
        </div>
        <div className="flexCardPayment">
          <CardPayment
            setTipoDePlano={setTipoDePlano}
            tittle={"Basic"}
            subtittle={"Não precisa de cartão de crédito"}
            middleTittle={"Grátis"}
            advantages={Basic}
            button={"Fazer login"}
            buttonCss={{
              width: "15.1rem",
              color: "#1B2CC1",
            }}
          />
          <CardPayment
            setTipoDePlano={setTipoDePlano}
            planType="monthly"
            tittle={"Premium"}
            subtittle={"Pagamento mensal"}
            middleTittle={"R$ 7 ,90/mês"}
            advantages={Premium}
            payment={`/api-stripe/create-checkout-session-monthly`}
            button={"Assinar Premium"}
            buttonCss={{ width: "20.3rem", color: "#1B2CC1" }}
          />
          <CardPayment
            setTipoDePlano={setTipoDePlano}
            planType="yearly"
            tittle={"Premium Plus"}
            subtittle={"Pagamento anual"}
            middleTittle={"R$ 5 ,90/mês"}
            advantages={PremiumPlus}
            payment={`/api-stripe/create-checkout-session-yearly`}
            button={"Assinar Premium Plus"}
            buttonCss={{
              width: "24.6rem",
              backgroundColor: "#1B2CC1",
            }}
          />
        </div>
      </section>
    </StyledPayment>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Payment);
