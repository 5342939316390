import styled from "styled-components";

export const StyledLogin = styled.main`
  background-color: var(--Primary-100, #e6e5ff);
  min-height: calc(100vh - var(--NewFooterHeight) - var(--NavHeight));
  .loginContainer {
    display: flex;
    padding: 4.8rem 3.2rem;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    border-radius: 16px;
    background: var(--Primary-25, #f2f3ff);
    box-shadow: 0 0.2rem 0.8rem 0 rgba(0, 0, 0, 0.25);
    width: 42.4rem;
    /* min-height: 79.1rem; */
    margin: 7.2rem auto 9.6rem auto;

    h2 {
      color: var(--Grey-700, #33394d);
      text-align: center;
      font-family: "Work Sans";
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 24px */
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      width: 100%;
      border-bottom: 1px solid var(--Grey-200, #c5c8db);
      .mb8 {
        margin-bottom: 0.8rem;
      }
      .form-control {
        outline: none;
      }
      .form-group {
        display: flex;
        flex-direction: column;
        padding: 1rem 0;
        gap: 0.8rem;

        label {
          color: var(--Grey-500, #606780);
          /* Text/Large/Regular */
          font-family: "Work Sans";
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.16rem;
        }
        input {
          display: flex;
          height: 4.8rem;
          padding: 1rem 1.6rem;
          align-items: center;
          border-radius: 0.8rem;
          border: 1px solid var(--Grey-300, #a0a5bd);
          background: var(--Supporting-white, #fff);

          /* Shadow/xs */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          /* Text/Large/Regular */
          font-family: "Work Sans";
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.16px;
          &::placeholder {
            color: var(--Grey-300, #a0a5bd);
          }
        }
      }
      .formFooter {
        display: flex;
        justify-content: space-between;
        .flexRemember {
          display: flex;
          align-items: center;
          gap: 0.8rem;
          label {
            color: var(--Grey-500, #606780);

            /* Text/Medium/Regular */
            font-family: "Work Sans";
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
          }
          input {
            width: 1.6rem;
            height: 1.6rem;
            cursor: pointer;
          }
        }
        .link {
          color: var(--Primary-500, #1b2cc1);

          /* Text/Medium/Regular */
          font-family: "Work Sans";
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21px */
          text-decoration: none;
        }
      }
      button {
        margin: 2.4rem 0 2.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 5.6rem;
        padding: 2rem 3.2rem;
        border-radius: 0.6rem;
        border: none;
        background: var(--Primary-500, #1b2cc1);
        color: var(--Primary-050, #f0fdf4);
        font-family: "Work Sans";
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.8rem; /* 175% */
        cursor: pointer;
      }
    }
    .flexGoogleFacebook {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.2rem;

      button {
        cursor: pointer;
        width: 100%;
        display: flex;
        height: 4.8rem;
        padding: 1rem 1.6rem;
        justify-content: center;
        align-items: center;
        gap: 1.2rem;
        border-radius: 0.8rem;
        border: 1px solid var(--Grey-300, #a0a5bd);
        background: var(--Supporting-white, #fff);

        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        svg {
          justify-self: flex-end;
        }
        p {
          color: var(--Grey-500, #606780);

          /* Text/Large/Regular */
          font-family: "Work Sans";
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.16px;
        }
      }
    }
    .flexRegister {
      display: flex;
      flex-direction: column;
      gap: 3.2rem;

      .Register {
        color: var(--Grey-500, #606780);

        /* Text/Medium/Regular */
        font-family: "Work Sans";
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */

        a {
          color: var(--Primary-500, #1b2cc1);

          /* Text/Medium/Regular */
          font-family: "Work Sans";
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          text-decoration: none;
        }
      }
    }
    .errorMessage {
      display: flex;
      gap: 1rem;

      img {
        margin-top: 0.4rem;
        width: 2rem;
        height: 2rem;
      }
      p {
        align-self: flex-start;
        color: var(--Error-500, #d41e21);
        line-height: 1;
        /* Text/Large/Regular */
        font-family: "Work Sans";
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.16px;
      }
    }
    .invalid-feedback {
      color: var(--Error-500, #d41e21);

      /* Text/Medium/Regular */
      font-family: "Work Sans";
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
    }
    .errorForm {
      border: var(--Scale-0, 1px) solid var(--Error-400, #de4c49) !important;
    }
  }
  @media (max-width: 465px) {
    .loginContainer {
      width: 36rem;
    }
  }
  @media (max-width: 390px) {
    .loginContainer {
      width: 33rem;
    }
  }
`;
