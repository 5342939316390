import { React, useState, useEffect, Fragment } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { StyledNav } from "../styles/Nav";
import { connect } from "react-redux";
import { logout } from "../actions/auth";
import { useSelector } from "react-redux";

function Nav({ recarregar, setRecarregar, logout, isAuthenticated }) {
  const [hambestado, setHambestado] = useState(false);
  const [alturaMenu, setAlturaMenu] = useState(0);
  const [mostrar, setMostrar] = useState("0rem");
  const [pagou, setPagou] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  let pageActive = location.pathname;
  const user = useSelector((state) => state.auth.user);
  const Token = process.env.REACT_APP_TOKEN;
  const URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    async function requisicaoIsPayd(email) {
      const response = await fetch(`${URL}/user/users/?emails=${email}`, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      });
      const data = await response.json();
      return data.results[0].subscription_active;
    }

    if (user !== null) {
      requisicaoIsPayd(user.email).then((pagouAtt) => {
        setPagou(pagouAtt);
      });
    } else {
      setPagou(false);
    }
  }, [user]);

  if (location.pathname === "/") {
    pageActive = "/";
  }
  if (location.pathname === "/cargos") {
    pageActive = "/cargos";
  }
  if (location.pathname === "/concursos") {
    pageActive = "/concursos";
  }
  if (location.pathname === "/sobre") {
    pageActive = "/sobre";
  }
  if (location.pathname === "/blog") {
    pageActive = "/blog";
  }

  function signUpReRender() {
    if (location.pathname === "/cadastro") {
      navigate(0);
    } else {
      navigate("/cadastro");
    }
  }

  const guestLinks = () => (
    <Fragment>
      <li>
        <Link to="/login">Login</Link>
      </li>
      <li>
        <Link to="/cadastro" className="buttonSignUp" onClick={signUpReRender}>
          Registrar
        </Link>
      </li>
    </Fragment>
  );
  const authLinks = () => (
    <>
      <li>
        <a href="#!" onClick={logout}>
          Logout
        </a>
      </li>
      <li>
        <Link to="/conta">Minha conta</Link>
      </li>
    </>
  );

  // Esse useEffect é só pra acompanhar o nome da barra com a página que já veio pré-selecionada do link
  useEffect(() => {}, [pageActive]);

  return (
    <StyledNav className="Nav">
      <div className="navegation">
        <ul className="menu1">
          <Link to="/">
            <li className={pageActive === "/" ? "active" : null}>Início</li>
          </Link>
          <Link to="/cargos">
            <li className={pageActive === "/cargos" ? "active" : null}>
              Cargos
            </li>
          </Link>
          <Link to="/concursos">
            <li className={pageActive === "/concursos" ? "active" : null}>
              Concursos
            </li>
          </Link>
          <Link to="/sobre">
            <li className={pageActive === "/sobre" ? "active" : null}>Sobre</li>
          </Link>
          <Link to="/blog">
            <li className={pageActive === "/blog" ? "active" : null}>Blog</li>
          </Link>
        </ul>
        <ul className="menu2">
          {pagou ? null : (
            <Link to="/planos">
              <li className="premium">Premium</li>
            </Link>
          )}
          <ul>{isAuthenticated ? authLinks() : guestLinks()}</ul>
        </ul>
      </div>

      <div
        className={`hamburguer btn ${hambestado}`}
        onClick={() => {
          setHambestado(!hambestado);
          if (hambestado === true) {
            setAlturaMenu("0");
            setMostrar("0rem");
          } else {
            const alturaCalculada = `calc(100vh - 8rem)`;
            setAlturaMenu(alturaCalculada);
            setTimeout(() => {
              setMostrar("2rem");
            }, 200);
          }
        }}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <svg
        onClick={() => navigate("/")}
        className="logoMobile"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="35"
        viewBox="0 0 32 28"
        fill="none"
      >
        <path
          d="M29.766 15.7965C29.766 18.5001 27.6453 20.6911 25.0279 20.6911H16.4817L11.29 25.6809L9.22375 20.6911H6.1187C3.5013 20.6911 1.38062 18.5001 1.38062 15.7965V6.81248C1.38062 4.10889 3.5013 1.91791 6.1187 1.91791H25.0279C27.6453 1.91791 29.766 4.10889 29.766 6.81248"
          stroke="#5FCE1D"
          strokeWidth="2.49492"
          strokeMiterlimit="10"
        />
        <path
          d="M6.37042 14.3527C6.37042 12.8354 7.57706 12.0143 10.4258 11.7126C10.3827 11.0821 10.0697 10.6716 9.28722 10.6716C8.68391 10.6716 8.08058 10.9256 7.38201 11.3225L6.62445 9.92083C7.5385 9.36288 8.56822 8.97278 9.70001 8.97278C11.5417 8.97278 12.5601 10.0252 12.5601 12.2321V16.3487H10.8205L10.6662 15.6115H10.6141C10.0085 16.1446 9.32805 16.5233 8.51833 16.5233C7.20509 16.5233 6.37269 15.5662 6.37269 14.3527H6.37042ZM10.4258 14.2575V12.9987C8.90844 13.2028 8.41399 13.636 8.41399 14.1804C8.41399 14.6453 8.7338 14.8721 9.23959 14.8721C9.74538 14.8721 10.047 14.634 10.4258 14.2552V14.2575Z"
          fill="white"
        />
        <path
          d="M14.352 14.1114V6.17981H16.4863V14.1976C16.4863 14.6557 16.6972 14.8009 16.8719 14.8009C16.9535 14.8009 17.0125 14.8009 17.1327 14.7714L17.3867 16.3455C17.1508 16.4453 16.8016 16.5224 16.3162 16.5224C14.8533 16.5224 14.352 15.5675 14.352 14.1114Z"
          fill="white"
        />
        <path
          d="M18.0853 12.7507C18.0853 10.3556 19.7705 8.97432 21.5918 8.97432C23.4131 8.97432 25.1006 10.3578 25.1006 12.7507C25.1006 15.1436 23.4154 16.5226 21.5918 16.5226C19.7683 16.5226 18.0853 15.139 18.0853 12.7507ZM20.5054 5.54718H22.6828L24.2523 7.45013L23.4403 8.2077L21.6236 6.87178H21.5646L19.7479 8.2077L18.9359 7.45013L20.5054 5.54718ZM22.9141 12.7507C22.9141 11.5055 22.4764 10.6958 21.5941 10.6958C20.7118 10.6958 20.2741 11.5055 20.2741 12.7507C20.2741 13.9959 20.7118 14.8011 21.5941 14.8011C22.4764 14.8011 22.9141 13.9982 22.9141 12.7507Z"
          fill="white"
        />
      </svg>
      <div className="caixaMenu" style={{ height: `${alturaMenu}` }}>
        <Link
          to="/"
          onClick={() => {
            setHambestado(!hambestado);
            setAlturaMenu("0");
            setMostrar("0rem");
          }}
        >
          <button style={{ fontSize: mostrar }}>Início</button>
        </Link>

        <Link
          to="/cargos"
          onClick={() => {
            setHambestado(!hambestado);
            setAlturaMenu("0");
            setMostrar("0rem");
          }}
        >
          <button style={{ fontSize: mostrar }}>Cargos</button>
        </Link>

        <Link
          to="/concursos"
          onClick={() => {
            setHambestado(!hambestado);
            setAlturaMenu("0");
            setMostrar("0rem");
          }}
        >
          <button style={{ fontSize: mostrar }}>Concursos</button>
        </Link>

        <Link
          to="/sobre"
          onClick={() => {
            setHambestado(!hambestado);
            setAlturaMenu("0");
            setMostrar("0rem");
          }}
        >
          <button style={{ fontSize: mostrar }}>Sobre</button>
        </Link>
        <Link
          to="/planos"
          onClick={() => {
            setHambestado(!hambestado);
            setAlturaMenu("0");
            setMostrar("0rem");
          }}
        >
          {pagou ? null : (
            <button style={{ fontSize: mostrar, color: "#5fce1d" }}>
              Premium
            </button>
          )}
        </Link>
      </div>
      <ul className="menu2Mobile">
        <ul>{isAuthenticated ? authLinks() : guestLinks()}</ul>
      </ul>
    </StyledNav>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(Nav);
