import styled from "styled-components";

export const StyledFilterSubMenu = styled.div`
  .FilterSubMenu {
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;

    .seta {
      transition: all 0.5s;
    }
    h4 {
      font-weight: bold;
      font-size: 1.6rem;
      font-family: "Work Sans", sans-serif;
      color: var(--Grey-500);
    }
    img {
      width: 2rem;
      cursor: pointer;
    }
  }
  .flexSquares {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    font-family: "Work Sans", sans-serif;
    color: var(--Grey-500);
  }
  svg {
    cursor: pointer;
  }
  .divPopUp {
    position: relative;
  }

  @media (max-width: 680px) {
    .divPopUp {
      position: static;
    }
  }
`;
