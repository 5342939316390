import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Loader from "../UI/Loader";
import { connect } from "react-redux";
import { useSelector } from "react-redux";

function ProtectRouteFree({ isAuthenticated }) {
  const user = useSelector((state) => state.auth.user);
  const Token = process.env.REACT_APP_TOKEN;
  const URL = process.env.REACT_APP_API_URL;

  const [premium, setPremium] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let timeoutId;
    if (isAuthenticated !== null) {
      async function requisicaoIsPayd(email) {
        const response = await fetch(`${URL}/user/users/?emails=${email}`, {
          headers: {
            Authorization: `Token ${Token}`,
          },
        });
        const data = await response.json();
        return data.results[0].subscription_active;
      }
      if (user !== null) {
        requisicaoIsPayd(user.email).then((pagouAtt) => {
          setPremium(pagouAtt);
          setLoading(false);
        });
      }
    }
    timeoutId = setTimeout(() => {
      setLoading(false); // Força a parada do loader após 1 segundo caso não seja autenticado
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [user, Token, URL, isAuthenticated]);

  if (loading) {
    return (
      <main
        style={{
          minHeight: `calc(100vh - var(--FooterHeight) - var(--NavHeight))`,
          backgroundColor: "#f2f3ff",
        }}
      >
        <Loader
          css={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "64px",
            height: "64px",
          }}
        />
      </main>
    );
  }

  if (premium) {
    return <Navigate to="/" />;
  }
  if (!premium || !isAuthenticated) {
    return <Outlet />;
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(ProtectRouteFree);
