import React from "react";
import { StyledFooterShort } from "../styles/FooterShort";

function FooterShort() {
  return (
    <StyledFooterShort>
      <div className="final">
        <p>© Alô Concursos 2024. Todos os direitos reservados.</p>
      </div>
    </StyledFooterShort>
  );
}

export default FooterShort;
