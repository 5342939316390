import React from "react";
import filterImg from "../assets/filterImg.svg";
import { StyledFilterHide } from "../styles/FilterHide";

function FilterHide({ setOpen, open }) {
  return (
    <StyledFilterHide onClick={() => setOpen(!open)}>
      <img src={filterImg} alt="Filter" />
      <p>Mostrar filtro</p>
    </StyledFilterHide>
  );
}

export default FilterHide;
