import { StyledPagination } from "../styles/Pagination";
import { useEffect, useState } from "react";

function Pagination({ limit, total, offset, setOffset, setCurrentPage }) {
  const [maximoDeBotoesPaginacao, setMaximoDeBotoesPaginacao] = useState(9);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 668) {
        setMaximoDeBotoesPaginacao(1);
      } else {
        setMaximoDeBotoesPaginacao(9);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const maximoDeBotoesParaEsquerda = (maximoDeBotoesPaginacao - 1) / 2; // 4
  const paginaAtual = offset ? offset / limit + 1 : 1;
  const paginas = Math.ceil(total / limit);
  const maxPrimeira = Math.max(paginas - (maximoDeBotoesPaginacao - 1), 1);
  const primeiraPagina = Math.min(
    Math.max(paginaAtual - maximoDeBotoesParaEsquerda, 1),
    maxPrimeira
  );

  function quandoPaginaMuda(pagina) {
    setOffset((pagina - 1) * limit);
    setCurrentPage(pagina);
  }
  return (
    <StyledPagination className="Pagination">
      {paginaAtual === 1 ? null : (
        <li>
          <button
            onClick={() => {
              quandoPaginaMuda(paginaAtual - 1);
            }}
            disabled={paginaAtual === 1}
          >
            Anterior
          </button>
        </li>
      )}
      {Array.from({ length: Math.min(maximoDeBotoesPaginacao, paginas) })
        .map((_, index) => index + primeiraPagina)
        .map((pagina) => {
          return (
            <li key={pagina}>
              <button
                onClick={() => {
                  quandoPaginaMuda(pagina);
                }}
                className={pagina === paginaAtual ? "pageActive" : null}
              >
                {pagina}
              </button>
            </li>
          );
        })}
      {paginaAtual === paginas ? null : (
        <li>
          <button
            onClick={() => {
              quandoPaginaMuda(paginaAtual + 1);
            }}
            disabled={paginaAtual === paginas}
          >
            Próximo
          </button>
        </li>
      )}
    </StyledPagination>
  );
}

export default Pagination;
