import React, { useEffect, useState } from "react";
import { StyledUserAccountConta } from "../styles/UserAccountConta.js";
import BoxUserAccount from "./BoxUserAccount.jsx";
import { Link } from "react-router-dom";
import Loader from "./Loader.jsx";

function UserAccountConta({ dados }) {
  document.title = `Detalhes da conta - Alô Concursos`;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!dados) {
        setLoading(true);
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [dados]);

  useEffect(() => {
    if (dados) {
      setLoading(false);
    }
  }, [dados]);

  return (
    <StyledUserAccountConta>
      {loading ? (
        <Loader
          css={{
            width: "48px",
            height: "48px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      ) : dados ? (
        <>
          <h1>Conta</h1>
          <BoxUserAccount tittle={"Informações pessoais"}>
            <div>
              <span>Nome:</span>
              <p>{dados.username}</p>
            </div>
            <div>
              <span>Sobrenome:</span>
              <p>{dados.last_name}</p>
            </div>
            <div>
              <span>E-mail:</span>
              <p>{dados.email}</p>
            </div>
          </BoxUserAccount>
          <BoxUserAccount tittle={"Segurança"}>
            <Link to="/resetar-senha">Redefinir senha</Link>
          </BoxUserAccount>
          <BoxUserAccount tittle={"Termos e privacidade"}>
            <Link to="/termos">Termos de uso</Link>
            <Link to="/politica-de-uso-de-dados">
              Política de Uso dos dados
            </Link>
          </BoxUserAccount>
        </>
      ) : null}
    </StyledUserAccountConta>
  );
}

export default UserAccountConta;
