import styled from "styled-components";

export const StyledPagination = styled.ul`
  max-height: 45px;
  list-style: none;
  grid-column: 1/3;
  width: calc(100% - 3.2rem - 1.067rem - 23.2rem);
  margin: 0 0 0 auto;
  display: flex;
  justify-content: center;
  gap: 1.5rem;

  button {
    border-radius: 0.4rem;
    width: fit-content;
    font-size: 1.6rem;
    padding: 1rem 1.5rem;
    cursor: pointer;
    font-family: "Work Sans", sans-serif;
    background-color: white;
    color: var(--Primary-500);
    border: 1px solid var(--Primary-500);
  }
  .pageActive {
    background: none;
    font-weight: bold;
    border: none;
    font-family: "Work Sans", sans-serif;
    color: var(--Grey-500);
  }
  @media (max-width: 969px) {
    width: 100%;
    grid-column: 1/2;
  }
  @media (max-width: 680px) {
    width: 100%;
    justify-self: center;
    grid-column: 1/2;
    grid-row: 3/4;
  }
`;
