import React from "react";
import { StyledBlogHomepage } from "../styles/BlogHomepage";
import imageOfALatin from "../assets/imageOfALatin.png";
import BlogCard from "../components/BlogCard";
import umaProva from "../assets/umaProva.png";
import infographic from "../assets/infographic.png";
import aSleek from "../assets/aSleek.png";
import searchBackground from "../assets/searchBackground.png";
import SecondSearchBar from "../components/SecondSearchBar";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function BlogHomepage({ setUrlAtual, setTermoPesquisado, setSearchReset }) {
  const location = useLocation();

  useEffect(() => {
    document.title = "Blog da Alô Concursos";
    setUrlAtual(location.pathname);
  }, []);
  return (
    <StyledBlogHomepage>
      <section className="firstSection">
        <div className="boxTittle">
          <h1>Blog da Alô Concursos</h1>
          <p>Fique atualizado sobre o mundo dos concursos público</p>
        </div>
        <div className="mainPost">
          <img src={imageOfALatin} alt="imageOfALatin" />
          <div className="tittleAndText">
            <h2>
              Explorando as últimas pesquisas: estudos chave que estão moldando
              nossa compreensão em 2024
            </h2>
            <p>28 de novembro de 2024</p>
          </div>
        </div>
        <div className="posts">
          <h2>Últimos artigos</h2>
          <div className="gridCards">
            <BlogCard
              img={umaProva}
              imgName="professor aplicando prova"
              tittle="Empoderando: como o acesso à educação está transformando vidas"
              subttitle="16 de novembro de 2024"
            />
            <BlogCard
              img={infographic}
              imgName={"Gráfico sobre vida saudável"}
              tittle={"10 Dicas para um estilo de vida mais saudável"}
              subttitle={"14 de novembro de 2024"}
            />
            <BlogCard
              img={aSleek}
              imgName={"computador tecnológico"}
              tittle={"Explorando o impacto do Big Data no emprego público"}
              subttitle={"12 de novembro de 2024"}
            />
            <BlogCard
              img={aSleek}
              imgName={"computador tecnológico"}
              tittle={"Explorando o impacto do Big Data no emprego público"}
              subttitle={"12 de novembro de 2024"}
            />
            <BlogCard
              img={umaProva}
              imgName="professor aplicando prova"
              tittle="Empoderando: como o acesso à educação está transformando vidas"
              subttitle="16 de novembro de 2024"
            />
            <BlogCard
              img={infographic}
              imgName={"Gráfico sobre vida saudável"}
              tittle={"10 Dicas para um estilo de vida mais saudável"}
              subttitle={"14 de novembro de 2024"}
            />
            <BlogCard
              img={aSleek}
              imgName={"computador tecnológico"}
              tittle={"Explorando o impacto do Big Data no emprego público"}
              subttitle={"12 de novembro de 2024"}
            />
            <BlogCard
              img={aSleek}
              imgName={"computador tecnológico"}
              tittle={"Explorando o impacto do Big Data no emprego público"}
              subttitle={"12 de novembro de 2024"}
            />
            <BlogCard
              img={umaProva}
              imgName="professor aplicando prova"
              tittle="Empoderando: como o acesso à educação está transformando vidas"
              subttitle="16 de novembro de 2024"
            />
            <BlogCard
              img={infographic}
              imgName={"Gráfico sobre vida saudável"}
              tittle={"10 Dicas para um estilo de vida mais saudável"}
              subttitle={"14 de novembro de 2024"}
            />
            <BlogCard
              img={aSleek}
              imgName={"computador tecnológico"}
              tittle={"Explorando o impacto do Big Data no emprego público"}
              subttitle={"12 de novembro de 2024"}
            />
            <BlogCard
              img={aSleek}
              imgName={"computador tecnológico"}
              tittle={"Explorando o impacto do Big Data no emprego público"}
              subttitle={"12 de novembro de 2024"}
            />
          </div>
        </div>
        <div className="loadMore">
          <button>
            <p>Carregar mais</p>
          </button>
        </div>
      </section>
      <section className="secondSection">
        <div
          className="box"
          style={{ backgroundImage: `url(${searchBackground})` }}
        >
          <div className="flexSearchBlog">
            <div className="tittleAndText">
              <h2>Busque por cargos ou concursos</h2>
              <p>Faça sua busca usando a barra de pesquisa abaixo.</p>
            </div>
            <SecondSearchBar
              setSearchReset={setSearchReset}
              setTermoPesquisado={setTermoPesquisado}
              page="Blog"
              width="100%"
            />
          </div>
        </div>
      </section>
    </StyledBlogHomepage>
  );
}

export default BlogHomepage;
