import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Nav from "../components/Nav";
import { connect } from "react-redux";
import {
  checkAuthenticated,
  load_user,
  googleAuthenticate,
} from "../actions/auth";
import queryString from "query-string";
import Footer from "../components/Footer";
import WhiteNav from "../components/WhiteNav";
import FooterExpanded from "../components/FooterExpanded";
import FooterShort from "../components/FooterShort";

const Layout = ({
  checkAuthenticated,
  load_user,
  children,
  googleAuthenticate,
  setUrlAtual,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  let location = useLocation();
  useEffect(() => {
    const values = queryString.parse(location.search);
    const state = values.state ? values.state : null;
    const code = values.code ? values.code : null;

    if (state && code) {
      googleAuthenticate(state, code);
    } else {
      checkAuthenticated();
      load_user();
    }
  }, [location]);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoaded(true);
    };

    window.addEventListener("load", handleLoad);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <>
      {location.pathname === "/conta" || location.pathname === "/termos" ? (
        <WhiteNav />
      ) : location.pathname === "/blog" ? (
        <WhiteNav page={"blog"} line={"exist"} />
      ) : (
        <Nav setUrlAtual={setUrlAtual} />
      )}

      {children}
      {location.pathname === "/conta" || location.pathname === "/termos" ? (
        isLoaded && <Footer />
      ) : location.pathname === "/login" ||
        location.pathname === "/cadastro" ||
        location.pathname.includes("/resetar-senha") ||
        location.pathname.includes("/ativar") ? (
        isLoaded && <FooterShort />
      ) : isLoaded && location.pathname.includes("/blog") ? (
        <FooterExpanded page={"blog"} />
      ) : (
        <FooterExpanded />
      )}
    </>
  );
};

export default connect(null, {
  checkAuthenticated,
  load_user,
  googleAuthenticate,
})(Layout);
