import styled from "styled-components";

export const StyledUserAccount = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Scale-3, 24px);
  .overlay {
    position: fixed; /* Fixa o overlay cobrindo a tela inteira */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Preto com 50% de opacidade */
    z-index: 900; /* Coloca o overlay acima do conteúdo da página */
  }
  h1 {
    color: var(--Grey-700, #33394d);
    font-family: "Work Sans";
    font-size: 3.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.72px;
  }
  h5 {
    width: fit-content;
    color: var(--Grey-500, #606780);
    font-family: "Work Sans";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.16px;
  }
  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    .flexInfo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.2rem;
      .infoBox {
        width: 100%;
        display: flex;
        padding: var(--Scale-3, 24px);
        flex-direction: column;
        align-items: flex-start;
        gap: 1.6rem;
        border-radius: 16px;
        background: var(--Supporting-white, #fff);
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
        a {
          color: var(--Primary-500, #1b2cc1);
          font-family: "Work Sans";
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          letter-spacing: 0.16px;
          text-decoration-line: underline;
        }
        div {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 0.8rem;
          span {
            color: var(--Grey-700, #33394d);
            font-family: "Work Sans";
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: 0.16px;
          }
          p {
            color: var(--Grey-700, #33394d);
            font-family: "Work Sans";
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: 0.16px;
          }
        }
        hr {
          width: 100%;
          height: 1px;
          background: var(--Grey-100, #edeefa);
          opacity: 0.25;
        }
      }
    }
    .bandeira {
      width: 34px;
      height: var(--Scale-3, 24px);
    }
    .flexCard {
      display: flex;
      gap: 1rem !important;
      flex-direction: row !important;
    }
  }
  .sameSizePopUpCancel {
    min-height: 217px;
    z-index: 901;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    width: 505px;
    align-items: center;
    justify-content: center;
    gap: 24px;
    border-radius: var(--Radius-md, 8px);
    background: var(--Supporting-white, #fff);
    box-shadow: 0px 0px 64px 0px rgba(37, 42, 61, 0.25);
  }
  .popUpCancel {
    z-index: 901;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    width: 505px;
    padding: var(--Scale-2, 16px) 32px 48px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    border-radius: var(--Radius-md, 8px);
    background: var(--Supporting-white, #fff);
    box-shadow: 0px 0px 64px 0px rgba(37, 42, 61, 0.25);

    h1 {
      color: var(--Grey-700, #33394d);
      font-family: "Work Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }

    svg {
      align-self: end;
      cursor: pointer;
    }
    .flexTittleButton {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4rem;
    }
    .flexButtons {
      display: flex;
      align-items: flex-start;
      gap: 48px;

      button {
        cursor: pointer;
        background-color: transparent;
        display: flex;
        height: 48px;
        padding: var(--Scale-2, 16px) 32px;
        align-items: center;
        gap: var(--Scale-2, 16px);
        border-radius: var(--Radius-md, 8px);
        border: 1px solid var(--Primary-500, #1b2cc1);
        color: var(--Primary-500, #1b2cc1);
        text-align: center;
        font-family: "Work Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0.16px;
      }
    }
  }

  .sucessefulCancel {
    z-index: 901;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    width: 505px;
    padding: var(--Scale-2, 16px) 32px 48px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    border-radius: var(--Radius-md, 8px);
    background: var(--Supporting-white, #fff);
    box-shadow: 0px 0px 64px 0px rgba(37, 42, 61, 0.25);
    svg {
      align-self: end;
      cursor: pointer;
    }
    .flexContent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;
      .flexTittleText {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Radius-md, 8px);
        h1 {
          color: var(--Grey-700, #33394d);
          font-family: "Work Sans";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
        }
        p {
          color: var(--Grey-500, #606780);

          /* Text/Large/Regular */
          font-family: "Work Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.16px;
        }
      }
      button {
        cursor: pointer;
        background-color: transparent;
        display: flex;
        height: 48px;
        padding: var(--Scale-2, 16px) 32px;
        align-items: center;
        gap: var(--Scale-2, 16px);
        border-radius: var(--Radius-md, 8px);
        border: 1px solid var(--Primary-500, #1b2cc1);
        color: var(--Primary-500, #1b2cc1);
        text-align: center;
        font-family: "Work Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0.16px;
      }
    }
  }

  .flexCancelTime {
    display: flex;
    flex-direction: row !important;
    align-items: flex-start;
    gap: var(--Radius-md, 8px) !important;
  }
  strong {
    color: var(--Warning-700, #bd791b);
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.16px;
  }
`;
