import React from "react";
import { Link } from "react-router-dom";

function TermsData() {
  return (
    <>
      <h1>Política de Privacidade</h1>
      <p>
        Nós levamos extremamente a sério a proteção dos dados pessoais coletados
        e armazenados, e neste documento, vamos te explicar detalhadamente como
        e porque fazemos esta coleta, e como você pode pedir para retirar,
        alterar ou excluir seus dados de nossos servidores.
        <br />
        <br />
        Em caso de dúvidas de quaisquer tipos, você pode entrar em contato
        diretamente com nossa equipe pela aba de contato.
        <br />
        <br />
        Este documento foi criado pelo Advogado Diego Castro (OAB/PI 15.613) , e
        adaptado para utilização neste website.
      </p>
      <h5>1 – Sobre a Origem dos seus dados Pessoais</h5>
      <p>
        Nosso site pode coletar alguns dados pessoais seus para diferentes
        objetivos.{" "}
        <span>
          Para coletarmos estes dados, usamos as seguintes tecnologias:
        </span>
        <br />
        <br />
        <ul>
          <li>
            <span>WEBSITE:</span> Dentro do nosso site você poderá preencher
            formulários, enviar comentários e dúvidas, entrar em contato com
            nossa equipe ou se cadastrar na nossa plataforma, e seus dados serão
            salvos para posterior atendimento.
          </li>
          <li>
            <span>MENSAGENS E COMUNICAÇÕES:</span> Quando você se comunica com
            nossa equipe, seja pelo Whatsapp, por SMS, por E-mail ou quaisquer
            outros tipos de chat, alguns dados podem ficar salvos para futura
            conferência e facilitar a comunicação com nossos clientes e
            usuários.
          </li>
          <li>
            <span>DE MODO OFFLINE: </span>Alguns dados podem ser coletados
            offline, como, por exemplo, em eventos e organizações, onde podemos
            coletar seu e-mail ou outros dados, para manter comunicação e
            informações necessárias que você precisa ter conhecimento.
          </li>
          <li>
            <span>DADOS DE TERCEIROS: </span>Algumas vezes, terceiros podem
            enviar para nossa empresa alguns dados seus, como, por exemplo,
            dados recebidos por mídias sociais (Como Facebook, instagram, Tiktok
            ou outras redes sociais), do governo ou intermediadoras de
            pagamentos.
          </li>
        </ul>
      </p>
      <h5>2 – Como e porque coletamos seus dados:</h5>
      <p>
        <span>
          É importante que você, nosso usuário ou visitante, saiba como e quais
          dados pessoais coletamos e utilizamos quando você visita nosso site.
        </span>
        Destacamos que sempre tentamos coletar o mínimo de dados possíveis para
        sua maior segurança, porém, alguns dados são essenciais para podermos
        entregar o melhor serviço possível e até para o funcionamento do site,
        conforme descrevemos abaixo:
        <br />
        <br />
        <ul>
          <li>
            <span>Marketing:</span> Quando você preenche formulários e se
            cadastra no site, você poderá receber e-mails com promoções e
            novidades do site. Podemos coletar nome, e-mail e telefone.
          </li>
          <li>
            <span>Navegação ao Site:</span> quando você navega pelo nosso site,
            coletamos automaticamente dados como o seu endereço IP, tipo de
            navegador, páginas acessadas, tempo gasto em cada página e outras
            informações.
          </li>
          <li>
            <span>Informações de contato:</span> quando você entra em contato
            conosco, seja por e-mail, telefone ou chat, coletamos as suas
            informações de contato, como o seu nome, e-mail, número de telefone
            e a mensagem que você nos enviou.
          </li>
          <li>
            <span>Informações de uso dos serviços:</span> quando você usa os
            nossos serviços, coletamos dados sobre as suas atividades, como as
            pesquisas que você realiza, os vídeos que você assiste, as páginas
            que você visita e as compras que você faz e quando você compartilha
            conosco fotos, comentários, sugestões, perguntas, respostas,
            informações, textos, arquivos, gráficos, vídeos ou outros materiais,
            coletamos as informações que você nos enviar. Quando você realiza
            compras no site também coletamos as informações necessárias para
            efetuar as devidas cobranças.
          </li>
          <li>
            <span>Informações de localização:</span> quando você usa os nossos
            serviços, coletamos dados de localização precisos para fornecer os
            serviços que você solicitou. Por exemplo, quando você solicita um
            serviço de entrega, coletamos seu endereço para enviar a entrega
            para o local certo.
          </li>
        </ul>
      </p>
      <h5>3 – Sobre o uso de Cookies:</h5>
      <p>
        <span>
          Usamos cookies no nosso website e aplicativos para melhorar a sua
          experiência de navegação no nosso website.
        </span>{" "}
        Um cookie é um pequeno arquivo de texto que um site armazena no seu
        computador ou dispositivo móvel quando você visita o site.
        <br />
        <br />
        Os cookies nos permitem reconhecer o seu navegador e fornecer-lhe a
        melhor experiência ao navegar no nosso site. Além disso, os cookies nos
        ajudam a compreender quais seções do site são mais interessantes para
        você e quais conteúdos podem ser recomendados para você, site com o
        google, bing, utilizam cookies, e ao visitar cada um, você deve
        verificar a política referente a eles.
        <br />
        <br />
        Você pode a qualquer momento bloquear os cookies no seu navegador, ou
        limpar o cache para retirar os mesmos.
        <br />
        <br />É importante destacar que tentamos minimizar o uso de cookies não
        essenciais, mas utilizamos os necessários para entregar um melhor
        serviço, conforme a previsão de legítimo interesse na LGPD. Você pode a
        qualquer momento desativar os mesmos em seu navegador.
      </p>
      <h5>4 – Com quem compartilhamos os seus dados pessoais:</h5>
      <p>
        <span>Prestadores de Serviços:</span> compartilhamos os seus dados
        pessoais com os nossos prestadores de serviços, que prestam serviços de
        marketing, análise de dados, pagamentos, envio de e-mails e outros
        serviços para nós. Esses prestadores de serviços só podem usar os seus
        dados pessoais para realizar esses serviços para nós e conforme as
        nossas instruções.
        <br />
        <br />
        <span>Anunciantes e Parceiros Comerciais:</span> também é possível que
        compartilhemos os seus dados pessoais com empresas que realizam
        anúncios, ou parceiros comerciais. No entanto, só compartilhamos os seus
        dados pessoais conforme as suas instruções.
        <br />
        <br />
        <span>Comunidades Online:</span> quando você se junta a uma das nossas
        comunidades online, os seus dados pessoais podem ser vistos por outros
        membros dessa comunidade.
        <br />
        <br />
        <span>Investidores:</span> podemos compartilhar os seus dados pessoais
        com os nossos investidores. No entanto, só compartilhamos os seus dados
        pessoais conforme as suas instruções.
        <br />
        <br />
        <span>Autoridades Governamentais e outras Partes:</span> às vezes,
        podemos ser obrigados judicialmente a liberar ou divulgar os seus dados
        às autoridades governamentais, ou outras partes, conforme exigido por
        lei. Ou por outros motivos, como:
        <ul style={{ listStyleType: "upper-roman" }}>
          <li>Cumprir uma obrigação legal;</li>
          <li>Proteger os direitos, a propriedade ou a segurança da Página;</li>
          <li>
            Evitar um dano real ou potencial aos bens, ou à segurança das
            pessoas;
          </li>
          <li>De outra forma, conforme permitido por lei.</li>
        </ul>
      </p>
      <h5>5- Sobre seus Direitos em relação aos Dados coletados</h5>
      <p>
        O presente texto tem por objetivo esclarecer os nossos leitores e
        visitantes sobre quais são os seus direitos em relação aos dados
        pessoais.
        <br />
        <br />
        <span>
          De acordo com a Lei Geral de Proteção de Dados Pessoais (LGPD), toda
          pessoa tem o direito à informação, à transparência, à liberdade e à
          autonomia na tomada de decisões.
        </span>
        <br />
        <br />
        Com relação aos dados pessoais, a pessoa tem o direito ao acesso, à
        correção, à eliminação, à portabilidade, à limitação do tratamento e à
        oposição.
        <br />
        <br />A pessoa também tem o direito de negar a qualquer momento que seus
        dados pessoais sejam utilizados para fins de marketing e ao recebimento
        de comunicações comerciais.
        <br />
        <br />
        Para exercer os seus direitos, basta entrar em contato com a nossa
        equipe.
        <br />
        <br />
        Esclarecemos que os dados pessoais são tratados de forma confidencial e
        que tomamos todas e quaisquer medidas de segurança necessárias para
        garantir a proteção dos dados.
      </p>
      <h5>6 – Sobre a segurança que aplicamos no tratamento dos seus dados</h5>
      <p>
        A segurança é um dos pilares fundamentais da nossa política de
        privacidade.
        <br />
        <br />
        Todos os dados pessoais que você fornecer ao site serão tratados com
        total confidencialidade e segurança, de acordo com as normas legais
        aplicáveis.
        <br />
        <br />
        <span>
          Para garantir a proteção dos seus dados pessoais, o site utiliza
          diversas medidas de segurança, como por exemplo:
        </span>
        <ul style={{ listStyleType: "disc" }}>
          <li>Criptografia SSL (Secure Socket Layer);</li>
          <li>Firewalls;</li>
          <li>Sistemas de detecção de invasões;</li>
          <li>Monitoramento constante das redes;</li>
          <li>Uso de senhas seguras;</li>
          <li>Verificação periódica da segurança dos sistemas.</li>
        </ul>
        Todas as informações pessoais são armazenadas em servidores seguros,
        protegidos contra acessos não autorizados, utilizando os mais modernos e
        avançados sistemas de segurança.
        <br />
        <br />
        Nossa equipe de segurança está constantemente monitorando os sistemas e
        atualizando as medidas de proteção, para garantir que seus dados estejam
        sempre seguros.
        <br />
        <br />
        Caso você tenha alguma dúvida ou preocupação em relação à segurança dos
        seus dados pessoais, entre em contato conosco.
      </p>
      <h5>7 – Pedido de modificação, remoção ou alteração</h5>
      <p>
        <span>
          Entendemos que você possa, eventualmente, querer modificar, remover ou
          alterar seus dados pessoais.
        </span>
        <br />
        <br />
        Se você deseja fazer uma alteração, uma remoção ou uma modificação,
        entre em contato conosco e nós faremos o possível para atender à sua
        solicitação. No entanto, tenha em mente que, em alguns casos, pode não
        ser possível atender à sua solicitação, especialmente se for necessária
        para cumprir nossas obrigações legais ou contratuais.
        <br />
        <br />
        Além disso, lembre-se de que, se você solicitar a remoção de seus dados
        pessoais dos nossos banco de dados, isso pode afetar o uso de nosso site
        e serviços. Se você não puder fazer uma alteração ou uma modificação, ou
        se desejar excluir seus dados pessoais,{" "}
        <span>
          entre em contato conosco para podermos discutir outras opções, pelo
          endereço de e-mail{" "}
          <a href="mailto:admin@aloconcursos.com">admin@aloconcursos.com</a>
        </span>
        .
      </p>
    </>
  );
}

export default TermsData;
